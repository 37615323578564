varying vec3 vColor;
varying float vLifecycle;
varying float vDistanceFactor;
varying float vProximityFactor;
varying float vInteractionFactor;


void main()
{
    // Circular particle shape
    float distanceToCenter = length(gl_PointCoord - 0.5);
    if(distanceToCenter > 0.5)
        discard;

    // Smooth transition between white and color based on interaction
    vec3 finalColor = mix(vec3(1.0), vColor * 15.5, vInteractionFactor);
	float alpha = 1.0 - 1.1 * (vInteractionFactor * 0.2);

    gl_FragColor = vec4(finalColor, 0.4); 

    #include <tonemapping_fragment>
    #include <colorspace_fragment>
}