// pages/NotFound.js
import React from "react";
import { useTranslation } from "react-i18next";
import { SEO } from "../components/LangSeo";
import { NavLink } from "../App";

const NotFound = () => {
  const { t } = useTranslation("global");

  return (
    <div className="flex flex-col md:flex-row items-center justify-center min-h-screen bg-background-light p-4">
      <SEO page="notFound" />

      {/* SVG Map Animation */}
      <div className="md:w-3/5 flex justify-center mb-8 md:mb-0"></div>

      {/* Message Section */}
      <div className="md:w-2/5 text-center md:text-left">
        <h1 className="text-4xl font-bold mb-4 text-primary-500">
          {t("notFound.title", "Oops! You're lost...")}
        </h1>
        <p className="text-lg mb-6 text-gray-700">
          {t(
            "notFound.message",
            "But don't worry, our delicious dishes are just around the corner in Spilimbergo!"
          )}
        </p>

        <NavLink
          to="/"
          className="text-secondary-200 border border-secondary-200 p-2  mt-2 underline text-sm text-left hover:text-secondary-400 transition"
        >
          {t("notFound.homeLink", "Return to Home")}
        </NavLink>
      </div>
    </div>
  );
};

export default NotFound;
