

#include ../includes/simplexNoise4d.glsl

vec3 rgbToHsv(vec3 c) {
vec4 K = vec4(0.0, -1.0/3.0, 2.0/3.0, -1.0);
vec4 p = mix(vec4(c.bg, K.wz), vec4(c.gb, K.xy), step(c.b, c.g));
vec4 q = mix(vec4(p.xyw, c.r), vec4(c.r, p.yzx), step(p.x, c.r));

float d = q.x - min(q.w, q.y);
float e = 1.0e-10;
return vec3(abs(q.z + (q.w - q.y) / (6.0 * d + e)), d / (q.x + e), q.x);
}

vec3 hsvToRgb(vec3 c) {
    vec4 K = vec4(1.0, 2.0/3.0, 1.0/3.0, 3.0);
    vec3 p = abs(fract(c.xxx + K.xyz) * 6.0 - K.www);
    return c.z * mix(K.xxx, clamp(p - K.xxx, 0.0, 1.0), c.y);
}



uniform vec2 uResolution;
uniform float uSize;
uniform sampler2D uParticlesTexture;
uniform float uTime;
uniform sampler2D uBaseTexture;

attribute vec2 aParticlesUv;
attribute vec3 aColor;
attribute vec3 bColor;
attribute float aSize;

varying vec3 vColor;
varying float vLifecycle;
varying vec2 vParticlesUv;
varying float vDistanceFactor; // New varying for distance 
varying float vProximityFactor;
varying float vInteractionFactor;


void main()
{
    vec4 particle = texture(uParticlesTexture, aParticlesUv);

    // Calculate interaction factor 
    vec3 originalPosition = texture(uBaseTexture, aParticlesUv).xyz;
    float distanceToOriginal = length(particle.xyz - originalPosition);
    float interactionFactor = smoothstep(0.2, 0.5, distanceToOriginal);

    // Final position
    vec4 modelPosition = modelMatrix * vec4(particle.xyz, 1.0);
    vec4 viewPosition = viewMatrix * modelPosition;
    vec4 projectedPosition = projectionMatrix * viewPosition;
    gl_Position = projectedPosition;

    // Point size
    float lifecycle = particle.a;
    float size = sin(lifecycle * 0.1) * 0.5 + 0.5;  // Pulsating size
    float distanceFromOrigin = length(particle.xyz);
    float distanceFactor = 0.5 / (1.0 + interactionFactor * 0.9);

    gl_PointSize = size * aSize * uSize * uResolution.y * 1.8 * distanceFactor;
    gl_PointSize *= (1.0 / -viewPosition.z);

    // Calculate noise
    float noiseOrigin = simplexNoise(aColor);
    float noiseTarget = simplexNoise(aColor);
    float noise = mix(aColor.b, aColor.r, simplexNoise(aColor) * 2.1);

    // Interpolate colors
    vec3 mixedColor = mix(aColor, bColor, noise);

    // Convert to HSV
    vec3 hsv = rgbToHsv(aColor);

    

    hsv.y = max(hsv.y, 0.1); // Adjust saturation
    hsv.z = max(hsv.z, 0.2); // Adjust value
       float oscillation = sin(uTime * 0.3) * 0.5 + 0.5; // Oscillates between 0 and 1
    hsv.x += mix(0.0, 1.0, oscillation);

    // Convert back to RGB
    vec3 color = hsvToRgb(hsv);



    // Varyings
    vColor = color;
    vLifecycle = lifecycle;
    vParticlesUv = aParticlesUv;
    vDistanceFactor = distanceFactor;
    vInteractionFactor = interactionFactor;
}