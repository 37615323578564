// language-selector.js
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FaEdit, FaGlobe, FaRegEdit } from "react-icons/fa";
import { useNavigate, useLocation } from "react-router-dom";
import { routesMap } from "../routesMap"; // Assicurati che il percorso sia corretto

const languages = [
  { code: "en", lang: "English" },
  { code: "it", lang: "Italian" },
];

const getLocalizedPath = (path, language) => {
  if (routesMap[path]) {
    return routesMap[path][language] || path;
  }
  return path;
};

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const [hovered, setHovered] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const toggleEditMode = () => {
    if (window.location.hash === "#translate") {
      window.history.replaceState(null, null, " ");
    } else {
      window.location.hash = "#translate";
    }
  };
  useEffect(() => {
    // Inizializzazione lingua in base al browser
    const browserLang = navigator.language.split("-")[0];
    const supportedLang = languages.find((lang) => lang.code === browserLang);
    if (supportedLang && supportedLang.code !== i18n.language) {
      i18n.changeLanguage(supportedLang.code);
      const currentPath = location.pathname;
      const newPath = getLocalizedPath(currentPath, supportedLang.code);
      navigate(newPath, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeLanguage = (lng) => {
    if (lng === i18n.language) {
      setHovered(false);
      return; // Se è la stessa lingua, non faccio nulla
    }
    const currentPath = location.pathname;
    const newPath = getLocalizedPath(currentPath, lng);
    i18n.changeLanguage(lng);
    navigate(newPath, { replace: true });
    setHovered(false);
  };

  const handleMouseEnter = () => setHovered(true);
  const handleMouseLeave = () => setHovered(false);

  return (
    <div className="relative z-40 flex">
      {/*    <button
        onClick={toggleEditMode}
        className="ml-4 px-2 py-1 mr-2 bg-gray-200 rounded"
      >
        {window.location.hash === "#translate" ? "X" : <FaRegEdit />}
      </button> */}
      <button
        className="focus:outline-none flex items-center gap-0"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <FaGlobe className="mr-2" />
        {languages.find((lng) => lng.code === i18n.language)?.lang}
      </button>
      <div
        className={`absolute top-full bg-background-light right-0 shadow z-40 transition-transform-opacity duration-500 ease-custom-easing transform ${
          hovered
            ? "opacity-100 translate-y-0"
            : "opacity-0 -translate-y-2 pointer-events-none"
        }`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {languages
          .filter((lng) => lng.code !== i18n.language)
          .map((lng) => (
            <div
              key={lng.code}
              className="w-full text-left text-primary-400 hover:text-white hover:bg-secondary-400 py-1 px-2 cursor-pointer"
              onClick={() => changeLanguage(lng.code)}
            >
              {lng.lang}
            </div>
          ))}
      </div>
    </div>
  );
};

export default LanguageSelector;
