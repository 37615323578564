import React, {
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import AboutUs from "./pages/AboutUs";
import { useTranslation } from "react-i18next";
import Experience from "./Experience";
import LanguageSelector from "./components/language-selector";
import {
  Link,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { SEO } from "./components/LangSeo";
import ContactForm from "./components/Form";
import Footer from "./components/Footer";
import {
  motion,
  useScroll,
  useSpring,
  useTransform,
  AnimatePresence,
} from "framer-motion";
import {
  FaLaptopCode,
  FaCube,
  FaSearch,
  FaGlobe,
  FaHandsHelping,
  FaWrench,
  FaTools,
  FaLightbulb,
  FaRegHandshake,
  FaRocket,
  FaChartLine,
  FaReact,
  FaWordpressSimple,
  FaLeaf,
  FaCloud,
  FaBolt,
  FaDatabase,
  FaCode,
  FaChevronDown,
  FaChevronUp,
  FaCompactDisc,
  FaBars,
  FaArrowUp,
} from "react-icons/fa";
import FAQ from "./components/Faq";

// Importa la mappa delle rotte
import { routesMap } from "./routesMap";
import { loadTranslations } from "./components/translations";
import TranslationEditor from "./components/TranslationEditor";
import { IoMdCloseCircle } from "react-icons/io";
import { IoLogoWhatsapp } from "react-icons/io5";
import PrivacyPolicy from "./pages/Privacy";
import NotFound from "./pages/NotFound";

// ----------------------------------------------------
// Context e Helper
// ----------------------------------------------------
export const AppContext = React.createContext(null);

// Hook personalizzato per il toggle
export const useToggle = (initialValue = false) => {
  const [value, setValue] = useState(initialValue);
  const toggle = (newValue) => {
    setValue((prevValue) =>
      typeof newValue === "boolean" ? newValue : !prevValue
    );
  };
  return [value, toggle];
};

// ----------------------------------------------------
// Funzioni Helper
// ----------------------------------------------------

// Funzione per rimuovere il prefisso lingua
const stripLang = (path) => path.replace(/^\/(en)/, "") || "/";

// Funzione per ottenere il percorso localizzato
const getLocalizedPath = (path, language) => {
  if (routesMap[path]) {
    return routesMap[path][language] || path;
  }
  return path;
};

// Funzione per ottenere l'indice della pagina per le animazioni
const navOrder = [
  "/",
  "/about",
  "/celebrations",
  "/gift-card",
  "/aperitivo-menu",
  "/menu-carta",
  "/wine-list",
  "/reviews",
  "/privacy",
];

const getPageIndex = (path) => {
  const cleanPath = stripLang(path);
  return navOrder.indexOf(cleanPath);
};

// Define animation variants
const buttonVariants = {
  hidden: { y: "100%", opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.8,
      ease: [0.32, 0.72, 0, 1],
      opacity: { duration: 0.3 },
    },
  },
  exit: {
    y: "100%",
    opacity: 0,
    transition: {
      duration: 0.8,
      ease: [0.32, 0.72, 0, 1],
      opacity: { duration: 0.3 },
    },
  },
};

// Slightly faster transitions for page animations
const getPageVariants = (direction) => ({
  initial: {
    x: direction > 0 ? "100%" : "-100%",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    zIndex: 1,
    opacity: 0,
  },
  animate: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 0.8,
      ease: [0.32, 0.72, 0, 1],
      opacity: { duration: 0.3 },
    },
    zIndex: 2,
  },
  exit: {
    x: direction > 0 ? "-100%" : "100%",
    opacity: 0,
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    zIndex: 1,
    transition: {
      duration: 0.8,
      ease: [0.32, 0.72, 0, 1],
      opacity: { duration: 0.3 },
    },
  },
});

// Wrapper component to handle page transitions
const PageTransitionWrapper = ({ children, direction, setIsTransitioning }) => {
  return (
    <motion.div
      variants={getPageVariants(direction)}
      initial="initial"
      animate="animate"
      exit="exit"
      onAnimationStart={() => setIsTransitioning(true)}
      onAnimationComplete={() => setIsTransitioning(false)}
      style={{
        position: "relative",
        width: "100%",
        minHeight: "100vh",
      }}
    >
      {children}
    </motion.div>
  );
};

// Componente NavLink personalizzato
export const NavLink = ({ to, children, className = "" }) => {
  const location = useLocation();
  const { t, i18n } = useTranslation("global");
  const { closeMenu, setDirection } = useContext(AppContext);
  const navigate = useNavigate();
  const localizedTo = getLocalizedPath(to, i18n.language);

  const handleClick = (e) => {
    e.preventDefault();
    // Se il link è a una sezione della pagina, scrolla verso di essa
    if (to.startsWith("#")) {
      const target = document.querySelector(to);
      if (target) {
        target.scrollIntoView({ behavior: "smooth" });
        closeMenu();
      }
      return;
    }

    window.scrollTo({ top: 0, behavior: "instant" });

    // Calcoliamo direzione per animazione
    const currentIndex = getPageIndex(location.pathname);
    const newIndex = getPageIndex(localizedTo);
    const dir = newIndex > currentIndex ? 1 : -1;
    setDirection(dir);

    if (location.pathname === localizedTo) return;
    navigate(localizedTo);
    closeMenu();
  };

  const isActive = stripLang(location.pathname) === stripLang(localizedTo);
  const baseClasses =
    "block hover:text-secondary-400 transition-colors duration-300";
  const activeClasses = isActive ? "underline" : "";

  return (
    <Link
      to={localizedTo}
      className={`${baseClasses} ${activeClasses} ${className}`.trim()}
      onClick={handleClick}
    >
      {children}
    </Link>
  );
};

// ----------------------------------------------------
// Componente App
// ----------------------------------------------------
function App() {
  const { t, i18n } = useTranslation("global");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [isEditMode, setIsEditMode] = useState(false);

  // Gestione Modalità Edit tramite URL Hash
  // Gestione Modalità Edit tramite URL Hash
  useEffect(() => {
    const handleHashChange = () => {
      setIsEditMode(window.location.hash === "#translate");
      /*    console.log(
        "Hash changed:",
        window.location.hash,
        "isEditMode:",
        window.location.hash === "#translate"
      ); // Log per debug */
    };

    // Inizializza lo stato al montaggio
    handleHashChange();

    // Ascolta i cambiamenti dell'hash
    window.addEventListener("hashchange", handleHashChange);

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  // Carica le traduzioni dinamicamente
  useEffect(() => {
    const load = async () => {
      try {
        const translations = await loadTranslations(i18n.language);
        i18n.addResourceBundle(
          i18n.language,
          "global",
          translations,
          true,
          true
        );
        /*         console.log("Translations loaded for language:", i18n.language);
        console.log(
          "Resource Bundle:",
          i18n.getResourceBundle(i18n.language, "global")
        ); */
      } catch (error) {
        console.error("Error loading translations:", error);
      }
    };
    load();
  }, [i18n.language, i18n]);

  // Direzione per l'animazione
  const [direction, setDirection] = useState(1);
  // Se vogliamo bloccare lo scroll durante la transizione
  const [isTransitioning, setIsTransitioning] = useState(false);

  // Gestione Nav Scroll & Menu
  const navRef = useRef(null);
  const prevScrollPos = useRef(0);
  const [isAtTop, setIsAtTop] = useState(true);
  const [isNavHidden, setIsNavHidden] = useState(false);
  const scrollThreshold = 0;

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isMenuOpen]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      const scrollDifference = currentScrollPos - prevScrollPos.current;
      setIsAtTop(currentScrollPos === 0);
      if (!isMenuOpen) {
        if (currentScrollPos > scrollThreshold && scrollDifference > 0) {
          setIsNavHidden(true);
        } else if (scrollDifference < 0) {
          setIsNavHidden(false);
        }
      }
      prevScrollPos.current = currentScrollPos;
    };
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isMenuOpen]);

  // Determina se la navbar deve avere colori inversi
  const [isInverse, setIsInverse] = useState(false);

  useEffect(() => {
    const path = location.pathname;
    const strippedPath = stripLang(path);

    // Pagine che richiedono colori inversi
    const inversePages = ["/privacy", "/404", "/recensioni"];
    const inverseEnglishPages = ["/en/privacy", "/en/404", "/en/reviews"];
    const inverseItalianPages = ["/privacy", "/404"];

    if (
      inversePages.includes(strippedPath) ||
      inverseEnglishPages.includes(path) ||
      inverseItalianPages.includes(strippedPath)
    ) {
      setIsInverse(true);
    } else {
      setIsInverse(false);
    }
  }, [location.pathname]);

  // Gestione Lingua & path iniziale
  useEffect(() => {
    const path = location.pathname;
    const isEnglishPath = path.startsWith("/en");
    if (isEnglishPath) {
      if (i18n.language !== "en") {
        i18n.changeLanguage("en");
      }
    } else {
      if (i18n.language !== "it") {
        i18n.changeLanguage("it");
      }
    }
    const currentStripped = stripLang(path);
    const localized = getLocalizedPath(currentStripped, i18n.language);
    if (localized !== path) {
      navigate(localized, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const closeMenu = () => setIsMenuOpen(false);

  // Gestione swipe per mobile
  const startY = useRef(null);
  const handleTouchStart = (e) => {
    startY.current = e.touches[0].clientY;
  };
  const handleTouchMove = (e) => {
    if (startY.current === null) return;
    const currentY = e.touches[0].clientY;
    const diff = currentY - startY.current;
    if (diff > 50) {
      closeMenu();
      startY.current = null;
    }
  };
  const handleTouchEnd = () => {
    startY.current = null;
  };

  const navClasses = `
  fixed top-0 left-0 font-body right-0 w-full z-40 
  transition-transform duration-300 ease-in-out
  ${isNavHidden && !isMenuOpen ? "-translate-y-full" : "translate-y-0"}
  ${
    window.scrollY < 150 && !isMenuOpen && !isInverse
      ? "bg-transparent text-white"
      : "backdrop-blur-lg text-white shadow-md"
  }
  ${isMenuOpen ? "backdrop-blur-lg text-white" : ""}
  ${isInverse ? "text-inverse-text" : ""}
  px-4 py-2
`.trim();

  // Scroll to top istantaneo ogni volta che cambia pagina
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, [location]);

  const backOnTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // Determina se mostrare i pulsanti
  const [showButtons, setShowButtons] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      const shouldShow = currentScrollPos > 150 && !isNavHidden;
      setShowButtons(shouldShow);
    };
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isNavHidden]);

  return (
    <div
      className={`relative  min-h-screen ${
        isTransitioning ? "overflow-hidden" : ""
      }`}
    >
      {/* AnimatePresence per i pulsanti */}
      <AnimatePresence>
        {showButtons && (
          <>
            {/* Pulsante Back-to-Top */}
            <motion.button
              variants={buttonVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              className="bg-secondary-400 hover:bg-secondary-200 transition-colors duration-300 ease-custom-easing text-white p-2 z-50 fixed bottom-5 right-3"
              onClick={backOnTop}
            >
              <FaArrowUp />
            </motion.button>
          </>
        )}
      </AnimatePresence>

      <AppContext.Provider
        value={{ closeMenu, setDirection, isEditMode, setIsEditMode }}
      >
        <nav ref={navRef} className={navClasses}>
          <div className="flex justify-between items-center py-2 mx-auto">
            <button
              onClick={toggleMenu}
              className="block xl:hidden z-[80] relative"
              aria-label={isMenuOpen ? "Close menu" : "Open menu"}
            >
              {isMenuOpen ? (
                <IoMdCloseCircle size={24} />
              ) : (
                <FaBars size={24} />
              )}
            </button>

            <div className="flex justify-end xl:justify-between items-center w-full">
              <NavLink to="/" className="hidden xl:inline-block no-underline">
                <img src="/img/logo-kosteaz.png" className="w-40" />
              </NavLink>
              <ul
                className={`${
                  isInverse ? "text-secondary-200" : ""
                } hidden xl:flex space-x-2 2xl:space-x-6`}
              >
                {/* <NavLink to="/">{t("nav.home")}</NavLink>
                    <NavLink to="/about">{t("nav.who")}</NavLink>
                <NavLink to="/celebrations">{t("nav.celebrations")}</NavLink>
                <NavLink to="/gift-card">{t("nav.giftCard")}</NavLink>
                <NavLink to="/aperitivo-menu">{t("nav.aperitivo")}</NavLink>
                <NavLink to="/menu-carta">{t("nav.menuCarta")}</NavLink>
                <NavLink to="/wine-list">{t("nav.wineList")}</NavLink> 
                <NavLink
                  to="#footer"
                  className="block hover:text-secondary-400 transition-colors duration-300"
                >
                  {t("nav.contact")}
                </NavLink>*/}
              </ul>
              <div className="flex items-center">
                {/* Pulsante WhatsApp */}
                <a
                  href="https://wa.me/3517971005"
                  target="_blank"
                  rel="noopener noreferrer"
                  variants={buttonVariants}
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                  className="z-50 text-2xl mr-2 transition-colors duration-700 hover:text-green-600 text-secondary-400"
                >
                  <IoLogoWhatsapp />
                </a>
                <LanguageSelector />
              </div>
            </div>
          </div>
        </nav>

        <div
          className={`
            fixed top-0 left-0 right-0  backdrop-blur-lg text-white z-50 
            transition-transform duration-500 ease-in-out xl:hidden
            h-screen pt-5
            ${isMenuOpen ? "translate-y-20" : "translate-y-full"}
            overflow-auto
          `}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <div className="h-full flex flex-col justify-between">
            <ul className="flex flex-col font-heading font-bold space-y-2 p-4 mt-5 text-center text-2xl">
              <NavLink to="/">{t("nav.home")}</NavLink>
              {/*    <NavLink to="/about">{t("nav.who")}</NavLink>
              <NavLink to="/celebrations">{t("nav.celebrations")}</NavLink>
              <NavLink to="/gift-card">{t("nav.giftCard")}</NavLink>
              <NavLink to="/aperitivo-menu">{t("nav.aperitivo")}</NavLink>
              <NavLink to="/menu-carta">{t("nav.menuCarta")}</NavLink>
              <NavLink to="/wine-list">{t("nav.wineList")}</NavLink> */}
              <NavLink
                to="#footer"
                className="block hover:text-secondary-400 transition-colors duration-300"
              >
                {t("nav.contact")}
              </NavLink>
              <NavLink to="/" className="no-underline text-secondary-200">
                <img src="/img/logo-kosteaz.png" className="w-40" />
              </NavLink>
            </ul>
          </div>
        </div>

        {/* CONTENITORE DELLE PAGINE CON EFFETTO PUSH */}
        <AnimatePresence>
          <Routes location={location} key={location.pathname}>
            {/* Home - Italiano */}
            <Route
              path="/"
              element={
                <PageTransitionWrapper
                  direction={direction}
                  setIsTransitioning={setIsTransitioning}
                >
                  <SEO page="home" image={"/img/out1.webp"} />
                  <Home />
                  <ContactForm />
                  <Footer />
                </PageTransitionWrapper>
              }
            />

            {/* Home - Inglese */}
            <Route
              path="/en"
              element={
                <PageTransitionWrapper
                  direction={direction}
                  setIsTransitioning={setIsTransitioning}
                >
                  <SEO page="home" />
                  <Home />
                  <ContactForm />
                  <Footer />
                </PageTransitionWrapper>
              }
            />

            {/* About Us - Italiano */}
            <Route
              path="/chi-siamo"
              element={
                <PageTransitionWrapper
                  direction={direction}
                  setIsTransitioning={setIsTransitioning}
                >
                  <SEO page="about" />
                  <AboutUs />
                  <ContactForm />
                  <Footer />
                </PageTransitionWrapper>
              }
            />

            {/* About Us - Inglese */}
            <Route
              path="/en/about"
              element={
                <PageTransitionWrapper
                  direction={direction}
                  setIsTransitioning={setIsTransitioning}
                >
                  <SEO page="about" />
                  <AboutUs />
                  <ContactForm />
                  <Footer />
                </PageTransitionWrapper>
              }
            />

            {/* Privacy Policy - Italiano */}
            <Route
              path="/privacy"
              element={
                <PageTransitionWrapper
                  direction={direction}
                  setIsTransitioning={setIsTransitioning}
                >
                  <SEO page="privacy" />
                  <PrivacyPolicy />
                  <ContactForm />
                  <Footer />
                </PageTransitionWrapper>
              }
            />

            {/* Privacy Policy - Inglese */}
            <Route
              path="/en/privacy"
              element={
                <PageTransitionWrapper
                  direction={direction}
                  setIsTransitioning={setIsTransitioning}
                >
                  <SEO page="privacy" />
                  <PrivacyPolicy />
                  <ContactForm />
                  <Footer />
                </PageTransitionWrapper>
              }
            />

            {/* 404 - Italiano */}
            <Route
              path="/404"
              element={
                <PageTransitionWrapper
                  direction={direction}
                  setIsTransitioning={setIsTransitioning}
                >
                  <SEO page="404" />
                  <NotFound />
                  <ContactForm />
                  <Footer />
                </PageTransitionWrapper>
              }
            />

            {/* 404 - Inglese */}
            <Route
              path="/en/404"
              element={
                <PageTransitionWrapper
                  direction={direction}
                  setIsTransitioning={setIsTransitioning}
                >
                  <SEO page="404" />
                  <NotFound />
                  <ContactForm />
                  <Footer />
                </PageTransitionWrapper>
              }
            />

            {/* Route Catch-All */}
            <Route
              path="*"
              element={
                <Navigate
                  to={i18n.language === "en" ? "/en/404" : "/404"}
                  replace
                />
              }
            />
          </Routes>
        </AnimatePresence>
        {/* Componente per la Modalità di Modifica */}
        {isEditMode && <TranslationEditor />}
      </AppContext.Provider>
    </div>
  );
}

export default App;

export function BulletWithIcon({ icon: Icon, label }) {
  return (
    <li className="flex items-center gap-2 mb-2 text-white">
      <Icon className="text-enoOrange-200" />
      <span>{label}</span>
    </li>
  );
}

export function BrowserWindow({ img, rotateWindow, imageScroll, originClass }) {
  return (
    <div className="w-full lg:w-1/2 max-w-[600px] perspective-[1500px]">
      <motion.div
        className={`bg-[202020] external-div active p-1  w-full  rounded-none overflow-hidden shadow-2xl ${originClass}`}
        style={{
          rotateY: rotateWindow,
          transformStyle: "preserve-3d",
        }}
      >
        {/* Header del browser finto */}
        <div className="flex items-center bg-[#202020]/60 backdrop-blur-2xl rounded-t-xl px-3 py-2">
          <div className="h-3 w-3 bg-red-500 rounded-full mr-2"></div>
          <div className="h-3 w-3 bg-yellow-500 rounded-full mr-2"></div>
          <div className="h-3 w-3 bg-green-500 rounded-full mr-2"></div>
        </div>

        {/* Container con altezza responsive */}
        <div className="h-[40vh] md:h-[50vh] external-div acrive lg:h-[60vh] rounded-b-xl overflow-hidden">
          <motion.div
            className="w-full"
            style={{
              y: imageScroll,
            }}
          >
            <img
              className="w-full"
              src={img}
              alt="Project snapshot"
              draggable="false"
              style={{
                minHeight: "120vh", // Ridotto per mobile
                objectFit: "cover",
                objectPosition: "top",
              }}
            />
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
}

function ProjectSection({
  anchorSide = "left",
  projectImg,
  siteUrl,
  title,
  description,
  bullets = [],
}) {
  const sectionRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);

  // Controlla se è mobile
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  const { scrollYProgress } = useScroll({
    target: sectionRef,
    offset: ["start start", "end end"],
  });

  const startAngle = anchorSide === "left" ? 90 : -90;

  // Range di animazione adattivi per mobile
  const rotateWindow = useTransform(
    scrollYProgress,
    [0, isMobile ? 0.1 : 0.15],
    [startAngle, 0]
  );

  // Range di scroll ridotto su mobile
  const imageScroll = useTransform(
    scrollYProgress,
    [isMobile ? 0.1 : 0.15, isMobile ? 0.6 : 0.85],
    [0, isMobile ? -600 : -1200]
  );

  const originClass = anchorSide === "left" ? "origin-left" : "origin-right";

  return (
    <section
      ref={sectionRef}
      className="relative w-full min-h-[200vh] bg-[#202020]/20 backdrop-blur-xs md:min-h-[300vh]" // Altezza ridotta su mobile
    >
      <div className="sticky top-0 h-screen flex flex-col items-center justify-center px-4 py-8">
        <div className="w-full max-w-7xl h-full flex flex-col lg:flex-row items-center justify-center gap-6">
          {anchorSide === "left" && (
            <BrowserWindow
              img={projectImg}
              rotateWindow={rotateWindow}
              imageScroll={imageScroll}
              originClass={originClass}
            />
          )}

          <div
            className={`${
              anchorSide === "left" ? "lg:pl-10" : "lg:pr-10"
            } w-full lg:w-1/2 flex flex-col items-start justify-center`}
          >
            <a
              href={siteUrl}
              className="text-white text-lg sm:text-xl block underline mb-3"
              target="_blank"
              rel="noopener noreferrer"
            >
              {siteUrl.replace("https://", "")}
            </a>
            <h3 className="font-sans text-enoOrange-200 text-2xl sm:text-3xl mb-3 uppercase">
              {title}
            </h3>
            <p className="font-serif text-white text-base sm:text-lg mb-5 leading-relaxed">
              {description}
            </p>
            <ul className="list-none pl-0">
              {bullets.map((item, idx) => (
                <BulletWithIcon key={idx} icon={item.icon} label={item.label} />
              ))}
            </ul>
          </div>

          {anchorSide === "right" && (
            <BrowserWindow
              img={projectImg}
              rotateWindow={rotateWindow}
              imageScroll={imageScroll}
              originClass={originClass}
            />
          )}
        </div>
      </div>
    </section>
  );
}

function Home() {
  const [t] = useTranslation("global");
  const [scrollY, setScrollY] = useState(0);

  // Track scroll position
  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const contactContainerRef = useRef(null);

  const handleScroll = (e) => {
    e.preventDefault();
    const contactContainer = contactContainerRef.current;
    if (contactContainer) {
      contactContainer.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [openFAQIndex, setOpenFAQIndex] = useState(null);
  const handleToggle = (index) => {
    setOpenFAQIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const faqs = [
    {
      question: "Quali servizi offri?",
      answer:
        "Offro servizi di realizzazione siti web su misura, esperienze web 3D immersive, ottimizzazione delle performance e consulenza strategica per migliorare la tua presenza online.",
    },
    {
      question: "Come funziona il processo di sviluppo?",
      answer:
        "Il mio processo di sviluppo è basato su metodologia agile, che prevede un percorso flessibile e iterativo per garantire che il risultato finale sia perfettamente allineato con la tua visione.",
    },
    {
      question: "Quali tecnologie utilizzi?",
      answer:
        "Utilizzo tecnologie moderne come React, Tailwind CSS, Three.js, React Three Fiber, Blender, e strumenti per la gestione delle lingue come i18next.",
    },
    {
      question: "Quanto costa un sito web personalizzato?",
      answer:
        "I prezzi sono commisurati al progetto, ma generalmente non accetto offeete per meno di 2.000 euro per progetti senza 3D e meno di  5.000 euro per progetti che includono esperienze web 3D.",
    },
    {
      question: "Offri supporto post-lancio?",
      answer:
        "Sì, collaboriamo strategicamente anche dopo il lancio per utilizzare dati e feedback al fine di affinare ed evolvere la tua presenza online.",
    },
    {
      question: "Come posso contattarti?",
      answer:
        "Puoi contattarmi direttamente tramite il form nella sezione contatti o scrivermi su WhatsApp per una consulenza gratuita.",
    },
  ];

  // Sezione Servizi: tracking card attive su mobile
  const cardRefs = useRef([]);
  const [activeCards, setActiveCards] = useState([]);

  useEffect(() => {
    const isMobile = window.innerWidth <= 768;
    if (!isMobile) return;

    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0,
    };

    const callback = (entries) => {
      entries.forEach((entry) => {
        const index = cardRefs.current.indexOf(entry.target);
        if (entry.isIntersecting) {
          setActiveCards((prev) => {
            if (!prev.includes(index)) {
              return [...prev, index];
            }
            return prev;
          });
        } else {
          setActiveCards((prev) => prev.filter((i) => i !== index));
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);
    cardRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => {
      if (observer) {
        cardRefs.current.forEach((ref) => {
          if (ref) observer.unobserve(ref);
        });
      }
    };
  }, []);

  // Progress bar globale (se la vuoi)
  const { scrollYProgress } = useScroll();
  const scale = useSpring(scrollYProgress, { stiffness: 100, damping: 30 });
  const pathLength = useTransform(scale, [0, 1], [0, 1]);

  return (
    <>
      {/* Hero (puoi anche mantenere overflow-hidden qui, ma a livello sezione, non su body) */}
      <div
        className="m-0 h-screen md:h-[100vh] select-none overflow-hidden w-full snap-start flex flex-col md:flex-row 
        items-center justify-between"
      >
        <div className="w-full fixed h-screen z-0">
          <Suspense
            fallback={
              <div className="flex justify-center items-center h-screen">
                <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-enoOrange-200"></div>
              </div>
            }
          >
            <Experience scrollY={scrollY} />
          </Suspense>
        </div>

        <div className="w-full md:w-1/2 lg:w-2/4 h-0 z-0"></div>

        <div className="w-full md:w-1/2 lg:w-2/4 flex flex-col justify-center items-start z-20 p-4 lg:p-20">
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 0.8,
              delay: 0.5,
              ease: [0, 0.71, 0.2, 1.01],
            }}
          >
            <div className="mt-4 flex flex-col items-center lg:items-start gap-4">
              <h1 className="text-7xl font-sans text-white uppercase  font-bold">
                Creo e gestisco siti web
              </h1>
              <a
                onClick={handleScroll}
                href="#"
                className="text-white mb-14 md:mb-0 p-2 text-base px-6 border-2 rounded-2xl hover:border-white border-enoOrange-200 hover:rounded-lg hover:bg-enoOrange-200 
                hover:rotate-1 uppercase leading-[1] transition-rotate duration-300 ease-in-out text-center w-full sm:w-auto"
              >
                Contattami
              </a>
            </div>
          </motion.div>
        </div>
      </div>

      {/* Servizi */}
      <div className="h-auto relative snap-start flex flex-col items-center justify-center p-4 sm:p-8 md:p-16 lg:p-24 xl:p-32 gap-2 ">
        <h2 className="font-sans text-enoOrange-200 text-3xl sm:text-4xl md:text-8xl uppercase mb-6">
          I Miei Servizi
        </h2>
        <div className="w-full cursor-default max-w-6xl grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {/* Card 1 */}
          <div
            ref={(el) => (cardRefs.current[0] = el)}
            className={`external-div rounded-lg p-1 ${
              activeCards.includes(0) ? "active" : ""
            }`}
          >
            <div className="service-card h-full bg-[#202020]/90 backdrop-blur-3xl p-6 rounded-xl shadow-lg flex flex-col items-start text-left">
              <FaLaptopCode size={40} className="text-enoOrange-200 mb-4" />
              <h3 className="text-3xl font-sans uppercase text-white mb-4">
                Sviluppo Web Personalizzato
              </h3>
              <p className="text-white font-serif mb-4">
                Realizzo siti web su misura utilizzando React o WordPress,
                focalizzati su velocità e performance. Integro animazioni
                avanzate e design responsive per garantire un'esperienza utente
                superiore, riflettendo l'unicità del tuo brand.
              </p>
            </div>
          </div>

          {/* Card 2 */}
          <div
            ref={(el) => (cardRefs.current[1] = el)}
            className={`external-div rounded-lg p-1 ${
              activeCards.includes(1) ? "active" : ""
            }`}
          >
            <div className="service-card h-full bg-[#202020]/90 backdrop-blur-3xl p-6 rounded-xl shadow-lg flex flex-col items-start text-left">
              <FaCube size={40} className="text-enoOrange-200 mb-4" />
              <h3 className="text-3xl font-sans uppercase text-white mb-4">
                Esperienze Web 3D Avanzate
              </h3>
              <p className="text-white font-serif mb-4">
                Crea esperienze web immersive con elementi 3D utilizzando
                Three.js, React Three Fiber e Blender. Distinguersi nel mercato
                digitale con soluzioni innovative che catturano l'attenzione e
                coinvolgono i tuoi utenti in modi unici.
              </p>
            </div>
          </div>

          {/* Card 3 */}
          <div
            ref={(el) => (cardRefs.current[2] = el)}
            className={`external-div rounded-lg p-1 ${
              activeCards.includes(2) ? "active" : ""
            }`}
          >
            <div className="service-card h-full bg-[#202020]/90 backdrop-blur-3xl p-6 rounded-xl shadow-lg flex flex-col items-start text-left">
              <FaSearch size={40} className="text-enoOrange-200 mb-4" />
              <h3 className="text-3xl font-sans uppercase text-white mb-4">
                Ottimizzazione SEO e Performance
              </h3>
              <p className="text-white font-serif mb-4">
                Migliora la visibilità del tuo sito con ottimizzazioni SEO
                essenziali e strategie di performance avanzate. Utilizzo
                strumenti come Eco Grader per valutare e ridurre l'impatto
                ambientale del tuo sito, oltre a ottimizzazioni per Google
                Insights e altri strumenti di analisi.
              </p>
            </div>
          </div>

          {/* Card 4 */}
          <div
            ref={(el) => (cardRefs.current[3] = el)}
            className={`external-div rounded-lg p-1 ${
              activeCards.includes(3) ? "active" : ""
            }`}
          >
            <div className="service-card h-full bg-[#202020]/90 backdrop-blur-3xl p-6 rounded-xl shadow-lg flex flex-col items-start text-left">
              <FaTools size={40} className="text-enoOrange-200 mb-4" />
              <h3 className="text-3xl font-sans uppercase text-white mb-4">
                Sviluppo Blocchi Personalizzati per WordPress
              </h3>
              <p className="text-white font-serif mb-4">
                Offro lo sviluppo di blocchi personalizzati per WordPress,
                permettendo una gestione dei contenuti flessibile e intuitiva.
                Le mie soluzioni migliorano la funzionalità e l'usabilità del
                tuo sito, adattandosi perfettamente alle tue esigenze
                specifiche.
              </p>
            </div>
          </div>

          {/* Card 5 */}
          <div
            ref={(el) => (cardRefs.current[4] = el)}
            className={`external-div rounded-lg p-1 ${
              activeCards.includes(4) ? "active" : ""
            }`}
          >
            <div className="service-card h-full bg-[#202020]/90 backdrop-blur-3xl p-6 rounded-xl shadow-lg flex flex-col items-start text-left">
              <FaGlobe size={40} className="text-enoOrange-200 mb-4" />
              <h3 className="text-3xl font-sans uppercase text-white mb-4">
                Soluzioni Multilingua Avanzate
              </h3>
              <p className="text-white font-serif mb-4">
                Espandi il tuo business globalmente con implementazioni
                multilingua avanzate tramite i18n per React o WPML per
                WordPress. Garantisco una localizzazione accurata e
                personalizzata, permettendoti di raggiungere nuovi mercati senza
                barriere linguistiche.
              </p>
            </div>
          </div>

          {/* Card 6 */}
          <div
            ref={(el) => (cardRefs.current[5] = el)}
            className={`external-div rounded-lg p-1 ${
              activeCards.includes(5) ? "active" : ""
            }`}
          >
            <div className="service-card h-full bg-[#202020]/90 backdrop-blur-3xl p-6 rounded-xl shadow-lg flex flex-col items-start text-left">
              <FaHandsHelping size={40} className="text-enoOrange-200 mb-4" />
              <h3 className="text-3xl font-sans uppercase text-white mb-4">
                Supporto Continuativo Post-Lancio
              </h3>
              <p className="text-white font-serif mb-4">
                Mantengo e ottimizzo il tuo sito web dopo il lancio,
                intervenendo rapidamente per modifiche, miglioramenti e
                risoluzione di bug. Collaboro con esperti di SEO e design per
                garantire che il tuo progetto rimanga sempre all'avanguardia.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Performance Ottimizzata */}
      <div className="h-auto relative snap-start flex flex-col items-center justify-center p-4 sm:p-8 md:p-16 lg:p-24 xl:p-32 gap-8 ">
        <h2 className="font-sans text-enoOrange-200 text-3xl sm:text-4xl md:text-8xl uppercase mb-12">
          Performance Ottimizzata
        </h2>
        <div className="w-full max-w-6xl grid grid-cols-1 lg:grid-cols-2 gap-12">
          {/* Performance Ottimizzata */}
          <div className="flex flex-col items-center text-center bg-[#202020]/90 backdrop-blur-3xl p-6 rounded-xl shadow-lg">
            <FaChartLine size={40} className="text-enoOrange-200 mb-4" />
            <h3 className="text-2xl font-sans uppercase text-white mb-4">
              Performance Ottimizzata
            </h3>
            <p className="text-white font-serif mb-4">
              Utilizzo strumenti avanzati come <strong>Ecograder</strong> e{" "}
              <strong>Lighthouse</strong> per analizzare e ottimizzare le
              performance del tuo sito, garantendo velocità, efficienza e
              sostenibilità.
            </p>
            <div className="flex flex-wrap justify-center gap-4 mt-4">
              <div className="flex items-center">
                <FaReact size={24} className="text-blue-500 mr-2" />
                <span className="text-white">React</span>
              </div>
              <div className="flex items-center">
                <FaWordpressSimple size={24} className="text-blue-600 mr-2" />
                <span className="text-white">WordPress</span>
              </div>
              <div className="flex items-center">
                <FaLeaf size={24} className="text-green-500 mr-2" />
                <span className="text-white">Ecograder</span>
              </div>
              <div className="flex items-center">
                <FaSearch size={24} className="text-yellow-500 mr-2" />
                <span className="text-white">Lighthouse</span>
              </div>
            </div>
          </div>

          {/* Consumi del tuo Sito Web */}
          <div className="flex flex-col items-center text-center  p-6 rounded-xl shadow-lg">
            <FaLeaf size={40} className="text-enoOrange-200 mb-4" />
            <h3 className="text-2xl font-sans uppercase text-white mb-4">
              Consumi del tuo Sito Web
            </h3>
            <p className="text-white font-serif mb-4">
              Analizzo e ottimizzo i consumi energetici del tuo sito utilizzando
              strumenti come <strong>Ecograder</strong> per garantire un hosting
              "green" e ridurre l'impatto ambientale, migliorando l'efficienza
              complessiva.
            </p>
            <div className="flex flex-wrap justify-center gap-4 mt-4">
              <div className="flex items-center">
                <FaCloud size={24} className="text-blue-400 mr-2" />
                <span className="text-white">Hosting Green</span>
              </div>
              <div className="flex items-center">
                <FaBolt size={24} className="text-yellow-300 mr-2" />
                <span className="text-white">Ottimizzazione Energetica</span>
              </div>
              <div className="flex items-center">
                <FaDatabase size={24} className="text-purple-500 mr-2" />
                <span className="text-white">Database Efficiente</span>
              </div>
              <div className="flex items-center">
                <FaCode size={24} className="text-green-400 mr-2" />
                <span className="text-white">Codice Pulito</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Processo di Lavoro */}
      <ProcessoDiLavoro />

      {/* SEZIONE PROGETTI */}
      <div id="projects" className="relative snap-start ">
        <h2 className="font-sans text-enoOrange-200 text-3xl sm:text-4xl md:text-8xl uppercase text-center py-16">
          Progetti Selezionati
        </h2>

        {/* 1° Progetto */}
        <ProjectSection
          anchorSide="left"
          projectImg="/img/enotorre.jpeg"
          siteUrl="https://www.enotorrereecords.com"
          title="Dalla Grafica del CD al Sito Web 3D"
          description="Un progetto nato in piccolo e cresciuto in grande..."
          bullets={[
            { icon: FaReact, label: "React con Tailwind" },
            { icon: FaLeaf, label: "Blender per i mockup 3D" },
            { icon: FaWordpressSimple, label: "WordPress base blog" },
            { icon: FaGlobe, label: "i18next multilingua" },
          ]}
        />

        {/* 2° Progetto */}
        <ProjectSection
          anchorSide="right"
          projectImg="/img/agerussi.jpeg"
          siteUrl="https://www.agerussi.com"
          title="Da WordPress a React con Multilingua"
          description="Mantengo lo stile originale, aggiungendo fluidità..."
          bullets={[
            { icon: FaReact, label: "React + Tailwind" },
            { icon: FaGlobe, label: "i18next per la gestione lingue" },
            { icon: FaSearch, label: "Ottimizzazione SEO" },
          ]}
        />

        {/* 3° Progetto */}
        <ProjectSection
          anchorSide="left"
          projectImg="/img/casadipinta.jpeg"
          siteUrl="https://www.casadipinta.com"
          title="Effetti Interattivi su Scroll"
          description="Gallery con distorsione su hover, effetto vino che si riempie..."
          bullets={[
            { icon: FaReact, label: "React e animazioni custom" },
            { icon: FaCube, label: "Effetti 3D su scroll" },
            { icon: FaGlobe, label: "i18next multilingua" },
            { icon: FaTools, label: "Tailwind per styling reattivo" },
          ]}
        />
      </div>

      {/* FAQ */}
      <div className="h-auto relative snap-start flex flex-col items-center justify-center p-4 sm:p-8 md:p-16 lg:p-24 xl:p-32 gap-8 ">
        <h2 className="font-sans text-zucsGreen-200 text-3xl sm:text-4xl md:text-8xl uppercase mb-12">
          <span className="text-enoOrange-200">FAQ</span>
        </h2>
        <div className="external-div active w-full max-w-6xl">
          <div className="w-full max-w-6xl bg-[#202020] p-8 rounded-xl shadow-lg">
            {faqs.map((faq, index) => (
              <FAQ
                key={index}
                className=""
                question={faq.question}
                answer={faq.answer}
                isOpen={openFAQIndex === index}
                onToggle={() => handleToggle(index)}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export const GooeyText = ({ text, className }) => {
  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });
  const [isMobile, setIsMobile] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.matchMedia("(max-width: 600px)").matches);
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);

    const handleMouseMove = (event) => {
      const rect = containerRef.current?.getBoundingClientRect();
      if (!rect) return;

      const scrollX = window.scrollX;
      const scrollY = window.scrollY;

      setMousePos({
        x: event.clientX + scrollX - rect.left,
        y: event.clientY + scrollY - rect.top,
      });
    };

    if (!isMobile) {
      window.addEventListener("mousemove", handleMouseMove);
    }

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("resize", checkMobile);
    };
  }, [isMobile]);

  return (
    <>
      <div className="edit text-7xl">punta e scrivi</div>
      <div className="gooey-container" ref={containerRef}>
        <h1 className={className}>
          <div
            className="goo"
            contentEditable="true"
            suppressContentEditableWarning={true}
            spellCheck="false"
          >
            {text}
          </div>
        </h1>

        {!isMobile && (
          <div
            className="gooey-cursor"
            style={{
              position: "absolute",
              top: `${mousePos.y}px`,
              left: `${mousePos.x}px`,
              width: "100px",
              height: "100px",
              backgroundColor: "var(--color-highlight)",
              borderRadius: "50%",
              filter: "url(#goo)",
              zIndex: 30,
              transform: "translate(-50%, -50%)",
            }}
          />
        )}

        <svg style={{ visibility: "hidden", position: "absolute" }}>
          <defs>
            <filter id="goo">
              <feGaussianBlur
                in="SourceGraphic"
                stdDeviation="10"
                result="blur"
              />
              <feColorMatrix
                in="blur"
                mode="matrix"
                values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 19 -9"
                result="goo"
              />
              <feComposite in="SourceGraphic" in2="goo" operator="atop" />
            </filter>
          </defs>
        </svg>
      </div>
    </>
  );
};

function ProcessoDiLavoro() {
  const containerRef = useRef(null);

  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start 60%", "end 40%"],
  });

  const pathLengthRaw = useTransform(scrollYProgress, [0, 0.8], [0, 1]);
  const pathLength = useSpring(pathLengthRaw, {
    stiffness: 40, // Reduced for smoother animation
    damping: 30, // Increased for less oscillation
    mass: 1, // Increased for more stability
  });

  const [isCard1Active, setIsCard1Active] = useState(false);
  const [isCard2Active, setIsCard2Active] = useState(false);
  const [isCard3Active, setIsCard3Active] = useState(false);
  const [isCard4Active, setIsCard4Active] = useState(false);

  useEffect(() => {
    const unsubscribe = scrollYProgress.on("change", (val) => {
      if (val < 0.3) {
        setIsCard1Active(true);
        setIsCard2Active(false);
        setIsCard3Active(false);
        setIsCard4Active(false);
      } else if (val >= 0.25 && val < 0.5) {
        setIsCard1Active(false);
        setIsCard2Active(true);
        setIsCard3Active(false);
        setIsCard4Active(false);
      } else if (val >= 0.45 && val < 0.7) {
        setIsCard1Active(false);
        setIsCard2Active(false);
        setIsCard3Active(true);
        setIsCard4Active(false);
      } else if (val >= 0.65) {
        setIsCard1Active(false);
        setIsCard2Active(false);
        setIsCard3Active(false);
        setIsCard4Active(true);
      }
    });
    return () => unsubscribe();
  }, [scrollYProgress]);

  const iconStyle = (isActive) => ({
    scale: isActive ? 1.1 : 1, // Reduced scale for smoother animation
    transition: "all 0.5s cubic-bezier(0.4, 0, 0.2, 1)", // Smoother easing
  });

  const cardWrapperClass = (isActive) =>
    `w-full max-w-4xl p-2 rounded-xl transform transition-all duration-700 ease-out ${
      isActive ? "md:translate-x-6 md:scale-102 active external-div" : ""
    }`;

  return (
    <section
      ref={containerRef}
      className="relative w-full  text-white overflow-hidden"
    >
      <div className="py-24 px-4 max-w-7xl mx-auto">
        <h2 className="font-sans text-center text-enoOrange-200 text-4xl sm:text-5xl md:text-6xl lg:text-8xl uppercase mb-40">
          Come Lavoro
        </h2>

        <div className="relative w-full py-40">
          {/* Enhanced SVG container with neo effect */}
          <div className="absolute top-0 left-0 w-full h-full pointer-events-none">
            <svg
              className="w-full h-full"
              viewBox="0 0 800 2000"
              fill="none"
              preserveAspectRatio="xMidYMin meet"
              style={{
                transform: "translateX(-5%) scale(1.1)",
                "@media (maxWidth: 768px)": {
                  transform: "translateY(0) scale(2.2)",
                },
              }}
            >
              <defs>
                <linearGradient
                  id="timelineGradient"
                  x1="0%"
                  y1="0%"
                  x2="100%"
                  y2="0%"
                >
                  <stop offset="0%" stopColor="#d41374" />
                  <stop offset="100%" stopColor="#118eaa" />
                </linearGradient>
              </defs>
              {/* Adjusted path for better mobile visibility */}
              <motion.path
                d="M400 0 C400 300 250 600 250 1000 C250 1400 400 1700 400 2000"
                stroke="url(#timelineGradient)"
                strokeWidth="8"
                strokeLinecap="round"
                strokeDasharray="1"
                style={{ pathLength }}
              />
            </svg>
          </div>

          <div className="relative flex flex-col gap-32 sm:gap-48 md:gap-96 lg:gap-128">
            {/* Card components with gradient icons */}
            {[
              {
                isActive: isCard1Active,
                icon: FaLightbulb,
                title: "1. Analisi delle Esigenze",
                text: "Iniziamo con un'analisi approfondita delle tue necessità per definire un range di prezzi sensato, basato sulle specifiche del progetto e sulle tue esigenze uniche.",
              },
              {
                isActive: isCard2Active,
                icon: FaRegHandshake,
                title: "2. Proposta e Pianificazione",
                text: "Presento proposte personalizzate che, una volta accettate, danno il via allo sviluppo grafico e alla creazione di una bozza live, inclusi eventuali acquisti di dominio e hosting.",
              },
              {
                isActive: isCard3Active,
                icon: FaTools,
                title: "3. Sviluppo e Feedback",
                text: "Collaboriamo attivamente attraverso feedback in tempo reale sull'avanzamento del progetto e sui contenuti, assicurando che il risultato finale sia perfettamente allineato con la tua visione.",
              },
              {
                isActive: isCard4Active,
                icon: FaHandsHelping,
                title: "4. Supporto Continuativo Post-Lancio",
                text: "Mantengo e ottimizzo il tuo sito web dopo il lancio, intervenendo rapidamente per modifiche, miglioramenti e risoluzione di bug. Collaboro con esperti di SEO e design per garantire che il tuo progetto rimanga sempre all'avanguardia.",
              },
            ].map((card, index) => (
              <motion.div
                key={index}
                className={cardWrapperClass(card.isActive)}
                style={{
                  marginLeft: index % 2 === 0 ? "0rem" : "auto",
                  marginRight: index % 2 === 0 ? "auto" : "0rem",
                  transform: card.isActive ? "scale(1.02)" : "scale(1)",
                  transition: "all 0.5s cubic-bezier(0.4, 0, 0.2, 1)",
                }}
              >
                <div className="flex flex-col md:flex-row items-start gap-6 p-6 backdrop-blur-3xl rounded-lg bg-[#202020]/90">
                  <motion.div
                    className="md:mr-6 relative"
                    animate={iconStyle(card.isActive)}
                  >
                    {/* Gradient background for active icon */}

                    <card.icon
                      className={`w-16 h-16 md:w-20 md:h-20 ${
                        card.isActive ? "text-[#118eaa]" : "text-white"
                      }`}
                    />
                  </motion.div>
                  <div>
                    <h3 className="text-2xl md:text-3xl lg:text-4xl font-sans uppercase mb-4">
                      {card.title}
                    </h3>
                    <p className="text-base sm:text-lg md:text-xl font-serif leading-relaxed">
                      {card.text}
                    </p>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
