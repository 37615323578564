// LangSeo.js
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export function SEO({ page, image }) {
  // Added image prop
  const { t, i18n } = useTranslation("global");
  const location = useLocation();
  const lang = i18n.language;
  const isItalian = lang === "it";

  const baseUrl = "https://www.casadipinta.com";
  const currentPath = location.pathname;

  const getCanonicalUrl = () => {
    if (isItalian) {
      return `${baseUrl}${currentPath}`;
    }
    return `${baseUrl}${currentPath.replace("/it", "")}`;
  };

  const getAlternateUrl = (targetLang) => {
    if (targetLang === "it") {
      return `${baseUrl}/it${currentPath.replace("/it", "")}`;
    }
    return `${baseUrl}${currentPath.replace("/it", "")}`;
  };

  return (
    <Helmet>
      <html lang={lang} />
      <title>{t(`seo.${page}.title`)}</title>
      <meta name="robots" content="index, follow" />
      <meta name="author" content="Casa Dipinta" />
      <meta name="publisher" content="Casa Dipinta" />
      <meta name="description" content={t(`seo.${page}.description`)} />
      <meta name="keywords" content={t(`seo.${page}.keywords`)} />

      {/* Open Graph Meta Tags */}
      <meta property="og:title" content={t(`seo.${page}.title`)} />
      <meta property="og:description" content={t(`seo.${page}.description`)} />
      <meta property="og:url" content={getCanonicalUrl()} />
      <meta property="og:type" content="website" />
      {image && <meta property="og:image" content={`${baseUrl}${image}`} />}

      {/* Twitter Card Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={t(`seo.${page}.title`)} />
      <meta name="twitter:description" content={t(`seo.${page}.description`)} />
      {image && <meta name="twitter:image" content={`${baseUrl}${image}`} />}

      {/* Alternate Languages */}
      <link rel="alternate" hreflang="it" href={getAlternateUrl("it")} />
      <link rel="alternate" hreflang="en" href={getAlternateUrl("en")} />
      <link rel="canonical" href={getCanonicalUrl()} />
    </Helmet>
  );
}
