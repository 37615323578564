import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "../App";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
  FaTripadvisor,
} from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";

const Footer = () => {
  const { t } = useTranslation("global");

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust breakpoint as needed
    };

    // Initial check
    checkMobile();

    // Add event listener
    window.addEventListener("resize", checkMobile);

    // Clean up event listener on unmount
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  return (
    <footer className="text-white lg:py-20 pb-10 px-6 mt-[80vh] md:px-12 lg:px-20 font-body bg-[#202020]">
      {/* Upper Border */}
      <div className="mb-10 pt-6 border-t border-secondary-200/20 text-center text-sm"></div>

      {/* Main Content */}
      <div className="mx-auto grid grid-cols-1 backdrop-blur-md p-5 rounded-lg md:grid-cols-2 lg:grid-cols-4 gap-8 text-center md:text-left">
        {/* Azienda */}
        <div className="flex flex-col items-center md:items-start">
          <h3 className="font-heading font-bold text-lg mb-4">AZIENDA</h3>
          <p className="text-sm mb-2">Kosteaz di Zelenin Kostyantyn</p>
          <address className="not-italic text-sm mb-2">
            Via Udine, 16
            <br />
            33097 Spilimbergo (PN) Italy
          </address>
          <p className="text-sm mb-2">P. Iva e C.F: 01926460930</p>
          <a
            href="mailto:digital@kosteaz.com"
            className="text-sm hover:text-enoOrange-200 transition-colors duration-300"
          >
            digital@kosteaz.com
          </a>
        </div>

        {/* Link */}
        <div className="flex flex-col items-center md:items-start">
          <h3 className="font-heading font-bold text-lg mb-4">LINK</h3>
          <nav className="flex flex-col space-y-2">
            <NavLink to="/">{t("nav.home") || "Home"}</NavLink>
          </nav>
        </div>

        {/* Social Media */}
        <div className="flex flex-col items-center md:items-start">
          <h3 className="font-heading font-bold text-lg mb-4">Seguimi</h3>
          <div className="flex space-x-4">
            <a
              href="https://www.linkedin.com/in/kosteaz/"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-enoOrange-200 transition-colors duration-300"
            >
              <FaLinkedin size={20} />
            </a>
            <a
              href="https://www.instagram.com/kosteaz/"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-enoOrange-200 transition-colors duration-300"
            >
              <FaInstagram size={20} />
            </a>
            {/* Add more social links if needed */}
          </div>
        </div>

        {/* Contact Info */}
        <div className="flex flex-col items-center md:items-start">
          <h3 className="font-heading font-bold text-lg mb-4">CONTATTI</h3>
          <address className="not-italic flex flex-col space-y-2 text-sm">
            <a
              className="hover:text-enoOrange-200 transition-colors duration-300"
              href="https://www.google.it/maps/dir//casa+dipinta/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x477a2b23d35eedc1:0x31981ef84511b327?sa=X&ved=1t:3061&ictx=111"
            >
              Via Udine, 16
              <br />
              33097 Spilimbergo (PN) Italy
            </a>
            <a
              href="tel:+39042741697"
              className="hover:text-enoOrange-200 transition-colors duration-300"
            >
              +39 0427 41697
            </a>
            <a
              href="mailto:digital@kosteaz.com"
              className="hover:text-enoOrange-200 transition-colors duration-300"
            >
              digital@kosteaz.com
            </a>
          </address>
        </div>
      </div>

      {/* Bottom Bar */}
      <div className="mt-5 pt-6 border-t border-secondary-200/20 text-center text-sm">
        <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
          <p>
            &copy; {new Date().getFullYear()} @kosteaz. {"All rights reserved."}
          </p>
          <NavLink
            to="/privacy"
            className="hover:text-enoOrange-200 transition-colors duration-300"
          >
            {"Privacy Policy"}
          </NavLink>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
