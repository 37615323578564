// src/components/TranslationEditor.js
import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../App";
import { saveTranslations } from "../components/translations";
import {
  flattenTranslations,
  unflattenTranslations,
} from "../utils/translationUtils";
import { useLocation } from "react-router-dom"; // Corrected import
import { routesMap } from "../routesMap"; // Importa routesMap

function TranslationEditor() {
  const { i18n, ready } = useTranslation("global");
  const { isEditMode, setIsEditMode } = useContext(AppContext);

  const [flatTranslations, setFlatTranslations] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [message, setMessage] = useState(null);
  const location = useLocation();

  // Funzione per mappare il percorso corrente alla pagina
  const getCurrentPage = () => {
    const path = location.pathname.toLowerCase();
    for (const [basePath, localizedPaths] of Object.entries(routesMap)) {
      if (localizedPaths.it === path || localizedPaths.en === path) {
        // Mappa basePath a una chiave di traduzione
        switch (basePath) {
          case "/":
            return "home";
          case "/about":
            return "aboutUs";
          case "/celebrations":
            return "celebrations";
          case "/gift-card":
            return "giftCard";
          case "/aperitivo-menu":
            return "menuAperitivo";
          case "/menu-carta":
            return "menuCarta";
          case "/wine-list":
            return "wineList";
          case "/reviews":
            return "reviews";
          case "/privacy":
            return "privacy";
          case "/404":
            return "notFound";
          // Aggiungi altre mappature se necessario
          default:
            return null;
        }
      }
    }
    // Se nessuna corrispondenza, verifica se è la home in inglese o italiano
    if (path === "/en" || path === "/") return "home";
    return null;
  };

  // Carica le traduzioni correnti nel componente e appiattiscile
  useEffect(() => {
    if (!ready) {
      console.log("i18n non è ancora pronto.");
      return;
    }
    console.log("Lingua corrente in TranslationEditor:", i18n.language);
    const currentTranslations = i18n.getResourceBundle(i18n.language, "global");
    console.log("Traduzioni correnti:", currentTranslations);
    const flattened = flattenTranslations(currentTranslations || {});
    setFlatTranslations(flattened);
    console.log("Traduzioni appiattite:", flattened); // Log per debug
  }, [i18n.language, i18n, ready]);

  const handleChange = (key, value) => {
    setFlatTranslations((prev) => ({ ...prev, [key]: value }));
    console.log(`Modificato [${key}] a "${value}"`); // Log per verificare cambiamenti
  };

  // Funzione per chiudere la Modalità di Modifica
  const closeEditor = () => {
    // Rimuove l'hash mantenendo il percorso e le query string esistenti
    window.history.replaceState(
      null,
      "",
      window.location.pathname + window.location.search
    );
    setIsEditMode(false); // Imposta isEditMode a false per chiudere l'editor
  };

  const handleSave = async () => {
    setIsSaving(true);
    setMessage(null);
    try {
      // Ricostruisci le traduzioni annidate
      const nestedTranslations = unflattenTranslations(flatTranslations);
      console.log("Traduzioni annidate da salvare:", nestedTranslations); // Log per debug
      const result = await saveTranslations(i18n.language, nestedTranslations);
      setMessage({
        type: "success",
        text: result.message || "Traduzioni salvate con successo!",
      });
      // Aggiorna le risorse di i18n senza ricaricare la pagina
      i18n.addResourceBundle(
        i18n.language,
        "global",
        nestedTranslations,
        true,
        true
      );
      // Chiudi la Modalità di Modifica
      closeEditor();
    } catch (error) {
      setMessage({ type: "error", text: error.message });
      console.error("Errore nel salvataggio delle traduzioni:", error); // Log per debug
    } finally {
      setIsSaving(false);
    }
  };

  if (!isEditMode || !ready) return null;

  // Determina la pagina corrente
  const currentPage = getCurrentPage();

  // Filtra le traduzioni basate sulla pagina corrente
  const filteredTranslations = currentPage
    ? Object.keys(flatTranslations)
        .filter((key) => {
          return (
            key.startsWith(`${currentPage}.`) ||
            key === `${currentPage}` ||
            key.startsWith(`seo.${currentPage}.`) ||
            key === `seo.${currentPage}`
          );
        })
        .reduce((obj, key) => {
          obj[key] = flatTranslations[key];
          return obj;
        }, {})
    : flatTranslations;

  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg w-11/12 max-w-4xl h-5/6 flex flex-col">
        {/* Header */}
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-2xl">Modalità Modifica Traduzioni</h2>
          <button
            onClick={closeEditor}
            className="text-gray-500 hover:text-gray-700"
          >
            ✕
          </button>
        </div>
        {/* Corpo */}
        <div className="p-4 flex-1 overflow-y-auto">
          {message && (
            <div
              className={`mb-4 p-2 rounded ${
                message.type === "success"
                  ? "bg-green-200 text-green-800"
                  : "bg-red-200 text-red-800"
              }`}
            >
              {message.text}
            </div>
          )}
          <form>
            {Object.keys(filteredTranslations).map((key) => (
              <div key={key} className="mb-4">
                <label className="block font-bold mb-1">{key}</label>
                <input
                  type="text"
                  value={filteredTranslations[key]}
                  onChange={(e) => handleChange(key, e.target.value)}
                  className="w-full border px-2 py-1"
                />
              </div>
            ))}
          </form>
        </div>
        {/* Footer */}
        <div className="p-4 border-t flex justify-end">
          <button
            type="button"
            onClick={handleSave}
            className={`bg-blue-500 text-white px-4 py-2 rounded mr-2 ${
              isSaving ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={isSaving}
          >
            {isSaving ? "Salvataggio..." : "Salva"}
          </button>
          <button
            type="button"
            onClick={closeEditor}
            className="bg-gray-500 text-white px-4 py-2 rounded"
          >
            Chiudi
          </button>
        </div>
      </div>
    </div>
  );
}

export default TranslationEditor;
