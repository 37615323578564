// src/components/translations.js

export const loadTranslations = async (language, onProgress) => {
  const response = await fetch(
    `https://www.casadipinta.com/api.php?lang=${language}`
  );

  if (!response.ok) {
    throw new Error("Failed to load translations");
  }

  const contentLength = response.headers.get("Content-Length");

  if (!contentLength) {
    console.warn(
      "Content-Length header is missing. Progress tracking unavailable."
    );
    // Optionally, trigger an indeterminate progress state
    const result = await response.json();
    if (onProgress && typeof onProgress === "function") {
      onProgress(null); // Indicate indeterminate progress
    }
    return result;
  }

  const total = parseInt(contentLength, 10);
  let loaded = 0;
  const reader = response.body.getReader();
  const chunks = [];

  while (true) {
    const { done, value } = await reader.read();
    if (done) break;
    chunks.push(value);
    loaded += value.length;
    const percentage = Math.round((loaded / total) * 100);
    if (onProgress && typeof onProgress === "function") {
      onProgress(percentage);
    }
  }

  const chunksAll = new Uint8Array(loaded);
  let position = 0;
  for (let chunk of chunks) {
    chunksAll.set(chunk, position);
    position += chunk.length;
  }

  const result = new TextDecoder("utf-8").decode(chunksAll);
  return JSON.parse(result);
};

export const saveTranslations = async (language, translations) => {
  const response = await fetch(`https://www.casadipinta.com/api.php`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ language, translations }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error || "Failed to save translations");
  }

  const data = await response.json();
  return data;
};
