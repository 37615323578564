import React, { useRef, useMemo, useEffect, useState } from "react";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import {
  ContactShadows,
  Environment,
  Float,
  Html,
  OrbitControls,
  Plane,
  PresentationControls,
  useGLTF,
} from "@react-three/drei";
import * as THREE from "three";
import { GPUComputationRenderer } from "three/examples/jsm/misc/GPUComputationRenderer";
import { useControls } from "leva";
import { motion } from "framer-motion";
import { GooeyText } from "./App";
import Effects from "./Effect";

// Shaders
import particlesVertexShader from "../src/shaders/particles/vertex.glsl";
import particlesFragmentShader from "../src/shaders/particles/fragment.glsl";
import gpgpuParticlesShader from "../src/shaders/gpgpu/particles.glsl";
import { ToneMapping } from "@react-three/postprocessing";
import { ToneMappingMode } from "postprocessing";

// 1) UPDATED createGeometryFromModel to bake transforms
const createGeometryFromModel = (model) => {
  // Make sure transforms on the scene are up to date
  model.scene.updateMatrixWorld(true);

  // Grab the first child mesh (adjust if needed)
  const mesh = model.scene.children[0];

  // Clone geometry so we don’t mutate the original
  const geometry = mesh.geometry.clone();
  // Bake in any transforms the mesh might have
  geometry.applyMatrix4(mesh.matrixWorld);

  return {
    instance: geometry,
    count: geometry.attributes.position.count,
  };
};

function useIsMobile() {
  const [isMobile, setIsMobile] = useState(() => window.innerWidth < 1024);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 870);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return isMobile;
}

function ParticleSystem({ isMobile, scrollY }) {
  const { gl, camera, size } = useThree();
  const canvas = gl.domElement;
  const particlesRef = useRef();

  const totalScrollHeight =
    document.documentElement.scrollHeight - window.innerHeight;
  const normalizedScroll = Math.min(
    Math.max(scrollY / totalScrollHeight, 0),
    1
  );

  // Positions for both models
  const particleSystemPosition = useMemo(() => {
    return isMobile
      ? new THREE.Vector3(0, 2, 0)
      : new THREE.Vector3(-2.3, 0, 0);
  }, [isMobile]);
  const targetPosition = useMemo(() => {
    return isMobile ? new THREE.Vector3(0, 2, 0) : new THREE.Vector3(0, 0, 0);
  }, [isMobile]);
  const currentPosition = useMemo(() => new THREE.Vector3(), []);

  // Load GLTF models
  const gltf1 = useGLTF("../assets/laptopvertex.glb");
  const gltf2 = useGLTF("../assets/linke.glb");
  const geometries = useMemo(() => {
    return {
      model1: createGeometryFromModel(gltf1),
      model2: createGeometryFromModel(gltf2),
    };
  }, [gltf1, gltf2]);

  // GPGPU setup
  const gpgpu = useMemo(() => {
    const sizeTex = Math.ceil(
      Math.sqrt(Math.max(geometries.model1.count, geometries.model2.count))
    );
    const computation = new GPUComputationRenderer(sizeTex, sizeTex, gl);

    const createBaseParticlesTexture = (geometry) => {
      const texture = computation.createTexture();
      for (let i = 0; i < geometry.count; i++) {
        const i3 = i * 3;
        const i4 = i * 4;
        texture.image.data[i4 + 0] =
          geometry.instance.attributes.position.array[i3 + 0];
        texture.image.data[i4 + 1] =
          geometry.instance.attributes.position.array[i3 + 1];
        texture.image.data[i4 + 2] =
          geometry.instance.attributes.position.array[i3 + 2];
        texture.image.data[i4 + 3] = Math.random(); // random or color seed
      }
      return texture;
    };

    const baseParticlesTextures = {
      model1: createBaseParticlesTexture(geometries.model1),
      model2: createBaseParticlesTexture(geometries.model2),
    };

    const particlesVariable = computation.addVariable(
      "uParticles",
      gpgpuParticlesShader,
      baseParticlesTextures.model1
    );
    computation.setVariableDependencies(particlesVariable, [particlesVariable]);

    particlesVariable.material.uniforms = {
      uTime: { value: 0 },
      uDeltaTime: { value: 0 },
      uVelocityScale: { value: 1 },
      uBase: { value: baseParticlesTextures.model1 },
      uTargetBase: { value: baseParticlesTextures.model2 },
      uFlowFieldInfluence: { value: 0.05 },
      uFlowFieldStrength: { value: 0.3 },
      uFlowFieldFrequency: { value: 0.3 },
      uNoiseScale: { value: 1.0 },
      uScrollStrength: { value: 0 },
      uExplodeStrength: { value: 0.0 },
      uTransitionNoiseScale: { value: 1.0 },
      uMotionStrength: { value: 1.0 },
      uExplosionFactor: { value: 0.0 },
      uModel2Factor: { value: 0.0 },
      // Mouse Ray
      uMouseRayOrigin: { value: new THREE.Vector3(0, 0, 0) },
      uMouseRayDirection: { value: new THREE.Vector3(0, 0, -1) },
      uMouseHoverRadius: { value: 1.0 },
      uCursorInfluence: { value: 8.0 },
      // Click
      uClickPosition: { value: new THREE.Vector3(0, 0, 0) },
      uClickTime: { value: 0 },
    };

    computation.init();

    return {
      size: sizeTex,
      computation,
      particlesVariable,
      baseParticlesTextures,
    };
  }, [gl, geometries]);

  // Points geometry
  const particlesGeom = useMemo(() => {
    const uvArray = new Float32Array(gpgpu.size * gpgpu.size * 2);
    const sizeArray = new Float32Array(gpgpu.size * gpgpu.size);

    for (let y = 0; y < gpgpu.size; y++) {
      for (let x = 0; x < gpgpu.size; x++) {
        const i = y * gpgpu.size + x;
        const i2 = i * 2;
        uvArray[i2 + 0] = (x + 0.5) / gpgpu.size;
        uvArray[i2 + 1] = (y + 0.5) / gpgpu.size;
        sizeArray[i] = 0.4 + Math.random() * 0.6;
      }
    }

    const geometry = new THREE.BufferGeometry();
    geometry.setDrawRange(0, geometries.model1.count);
    geometry.setAttribute(
      "aParticlesUv",
      new THREE.BufferAttribute(uvArray, 2)
    );
    if (geometries.model1.instance.attributes.color) {
      geometry.setAttribute(
        "aColor",
        geometries.model1.instance.attributes.color
      );
    }
    geometry.setAttribute("aSize", new THREE.BufferAttribute(sizeArray, 1));

    return geometry;
  }, [gpgpu.size, geometries]);

  // Points material
  const particlesMaterial = useMemo(() => {
    return new THREE.ShaderMaterial({
      vertexShader: particlesVertexShader,
      fragmentShader: particlesFragmentShader,
      transparent: true,
      uniforms: {
        uSize: { value: isMobile ? 0.05 : 0.05 },
        uResolution: {
          value: new THREE.Vector2(
            size.width * window.devicePixelRatio,
            size.height * window.devicePixelRatio
          ),
        },
        uParticlesTexture: { value: null },
        uTime: { value: 0 },
        uBaseTexture: { value: gpgpu.baseParticlesTextures.model1 },
        uTargetBaseTexture: { value: gpgpu.baseParticlesTextures.model2 },
        uTransitionProgress: { value: 0 },
      },
    });
  }, [isMobile, size, gpgpu.baseParticlesTextures]);

  // Leva controls
  const {
    flowFieldEnabled,
    uSize,
    uFlowFieldInfluence,
    uFlowFieldStrength,
    uFlowFieldFrequency,
    uCursorInfluence,
    uNoiseScale,
    uExplodeStrength,
    uTransitionNoiseScale,
    uTimeScale,
    uMotionStrength,
    uMouseHoverRadius,
  } = useControls({
    flowFieldEnabled: { value: true },
    uSize: { value: isMobile ? 0.05 : 0.05, min: 0, max: 1, step: 0.001 },
    uFlowFieldInfluence: { value: 0.05, min: 0, max: 1, step: 0.001 },
    uFlowFieldStrength: { value: 0.3, min: 0, max: 10, step: 0.001 },
    uFlowFieldFrequency: { value: 0.3, min: 0, max: 1, step: 0.001 },
    uCursorInfluence: { value: 15.0, min: 0, max: 30, step: 0.01 },
    uNoiseScale: { value: 1, min: 0, max: 10, step: 0.01 },
    uExplodeStrength: { value: 0, min: 0, max: 200, step: 1 },
    uTransitionNoiseScale: { value: 1, min: 0, max: 10, step: 0.01 },
    uTimeScale: { value: 1, min: 0, max: 3, step: 0.1 },
    uMotionStrength: { value: 1.0, min: 0, max: 5, step: 0.01 },
    uMouseHoverRadius: { value: 1.0, min: 0, max: 5, step: 0.01 },
  });

  /*   const useParticleControls = (isMobile) => {
    // Return static values that were previously in useControls
    return {
      flowFieldEnabled: true,
      uSize: isMobile ? 0.05 : 0.15,
      uFlowFieldInfluence: 0.05,
      uFlowFieldStrength: 0.3,
      uFlowFieldFrequency: 0.3,
      uCursorInfluence: 15.0,
      uNoiseScale: 1,
      uExplodeStrength: 0,
      uTransitionNoiseScale: 1,
      uTimeScale: 1,
      uMotionStrength: 1.0,
      uMouseHoverRadius: 1.0,
    };
  };

  // Then in your ParticleSystem component, replace the useControls with:
  const {
    flowFieldEnabled,
    uSize,
    uFlowFieldInfluence,
    uFlowFieldStrength,
    uFlowFieldFrequency,
    uCursorInfluence,
    uNoiseScale,
    uExplodeStrength,
    uTransitionNoiseScale,
    uTimeScale,
    uMotionStrength,
    uMouseHoverRadius,
  } = useParticleControls(isMobile); */

  useEffect(() => {
    gl.setClearColor(0x000000, 0);
    particlesMaterial.uniforms.uSize.value = uSize;

    const uniforms = gpgpu.particlesVariable.material.uniforms;
    uniforms.uFlowFieldInfluence.value = flowFieldEnabled
      ? uFlowFieldInfluence
      : 0;
    uniforms.uFlowFieldStrength.value = uFlowFieldStrength;
    uniforms.uFlowFieldFrequency.value = uFlowFieldFrequency;
    uniforms.uCursorInfluence.value = uCursorInfluence;
    uniforms.uNoiseScale.value = uNoiseScale;
    uniforms.uExplodeStrength.value = uExplodeStrength;
    uniforms.uTransitionNoiseScale.value = uTransitionNoiseScale;
    uniforms.uMotionStrength.value = uMotionStrength;
    uniforms.uMouseHoverRadius.value = uMouseHoverRadius;
  }, [
    gl,
    particlesMaterial,
    gpgpu,
    uSize,
    flowFieldEnabled,
    uFlowFieldInfluence,
    uFlowFieldStrength,
    uFlowFieldFrequency,
    uCursorInfluence,
    uNoiseScale,
    uExplodeStrength,
    uTransitionNoiseScale,
    uMotionStrength,
    uMouseHoverRadius,
  ]);

  useEffect(() => {
    const handleResize = () => {
      particlesMaterial.uniforms.uResolution.value.set(
        size.width * window.devicePixelRatio,
        size.height * window.devicePixelRatio
      );
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [size, particlesMaterial]);

  // Mouse => local ray
  const invMat = useMemo(() => new THREE.Matrix4(), []);
  const localRayOrigin = useMemo(() => new THREE.Vector3(), []);
  const localRayDirection = useMemo(() => new THREE.Vector3(), []);
  const raycasterInstance = useMemo(() => new THREE.Raycaster(), []);

  useEffect(() => {
    const mousePos = new THREE.Vector2();

    const updateMouseRay = (clientX, clientY) => {
      const rect = canvas.getBoundingClientRect();
      mousePos.x = ((clientX - rect.left) / rect.width) * 2 - 1;
      mousePos.y = -((clientY - rect.top) / rect.height) * 2 + 1;
      raycasterInstance.setFromCamera(mousePos, camera);

      if (particlesRef.current) {
        invMat.copy(particlesRef.current.matrixWorld).invert();
      }

      localRayOrigin.copy(raycasterInstance.ray.origin).applyMatrix4(invMat);
      localRayDirection
        .copy(raycasterInstance.ray.direction)
        .transformDirection(invMat);

      const uniforms = gpgpu.particlesVariable.material.uniforms;
      uniforms.uMouseRayOrigin.value.copy(localRayOrigin);
      uniforms.uMouseRayDirection.value.copy(localRayDirection).normalize();
    };

    const handlePointerMove = (e) => updateMouseRay(e.clientX, e.clientY);
    const handleTouchMove = (e) => {
      e.preventDefault();
      if (e.touches.length > 0) {
        updateMouseRay(e.touches[0].clientX, e.touches[0].clientY);
      }
    };

    canvas.addEventListener("pointermove", handlePointerMove);
    canvas.addEventListener("touchmove", handleTouchMove, { passive: false });
    return () => {
      canvas.removeEventListener("pointermove", handlePointerMove);
      canvas.removeEventListener("touchmove", handleTouchMove);
    };
  }, [
    camera,
    canvas,
    gpgpu,
    invMat,
    localRayOrigin,
    localRayDirection,
    raycasterInstance,
  ]);

  // Right-click hold => slow down
  const [rightClickHeld, setRightClickHeld] = useState(false);
  useEffect(() => {
    const handlePointerDown = (e) => {
      if (e.button === 2) {
        setRightClickHeld(true);
      }
    };
    const handlePointerUp = (e) => {
      if (e.button === 2) {
        setRightClickHeld(false);
      }
    };
    window.addEventListener("pointerdown", handlePointerDown);
    window.addEventListener("pointerup", handlePointerUp);
    // prevent default context menu
    window.addEventListener("contextmenu", (e) => e.preventDefault());
    return () => {
      window.removeEventListener("pointerdown", handlePointerDown);
      window.removeEventListener("pointerup", handlePointerUp);
      window.removeEventListener("contextmenu", (e) => e.preventDefault());
    };
  }, []);

  const timeRef = useRef(0);
  const timeScaleRef = useRef(1);

  // Key transitions
  const computeExplosionFactor = (s) => {
    if (s < 0.05) return 0; // Start explosion earlier at 5%
    else if (s < 0.15) return (s - 0.05) / 0.1; // Explode between 5-15%
    else if (s < 0.8) return 1; // Stay exploded until 90%
    else if (s < 0.9) return 1;
    else return 1;
  };
  const computeModel2Factor = (s) => {
    if (s < 0.8) return 0;
    else if (s < 0.9) return (s - 0.8) / 0.1;
    else return 1;
  };

  const velocityScale = useMemo(() => {
    if (normalizedScroll < 0.05) {
      return 0.5; // Initial speed (half of original)
    } else if (normalizedScroll < 0.15) {
      // Gradual slowdown during explosion
      const t = (normalizedScroll - 0.05) / 0.1;
      return THREE.MathUtils.lerp(0.5, 0.1, t);
    } else if (normalizedScroll < 0.8) {
      return 0.01; // Even slower during the middle phase (was 0.02)
    } else {
      const t = (normalizedScroll - 0.8) / 0.2;
      return THREE.MathUtils.lerp(0.01, 0.5, t); // Gradual speed up at end
    }
  }, [normalizedScroll]);

  useEffect(() => {
    if (!particlesRef.current) return;
    const eFactor = computeExplosionFactor(normalizedScroll);
    const m2Factor = computeModel2Factor(normalizedScroll);

    const uniforms = gpgpu.particlesVariable.material.uniforms;
    uniforms.uExplosionFactor.value = eFactor;
    uniforms.uModel2Factor.value = m2Factor;
    uniforms.uVelocityScale.value = velocityScale;

    currentPosition.lerpVectors(
      particleSystemPosition,
      targetPosition,
      normalizedScroll
    );
    particlesRef.current.position.copy(currentPosition);

    const countModel1 = geometries.model1.count;
    const countModel2 = geometries.model2.count;
    const finalCount = Math.floor(
      (1.0 - m2Factor) * countModel1 + m2Factor * countModel2
    );
    particlesRef.current.geometry.setDrawRange(0, finalCount);
  }, [
    scrollY,
    normalizedScroll,
    velocityScale,
    currentPosition,
    gpgpu,
    particlesRef,
    geometries,
    particleSystemPosition,
    targetPosition,
  ]);

  useFrame((_, rawDelta) => {
    const delta = Math.min(rawDelta, 0.1);
    const targetScale = rightClickHeld ? 0.2 : 1.0;
    timeScaleRef.current = THREE.MathUtils.lerp(
      timeScaleRef.current,
      targetScale,
      0.1
    );

    timeRef.current += delta * timeScaleRef.current;
    const uniforms = gpgpu.particlesVariable.material.uniforms;
    uniforms.uTime.value = timeRef.current;
    uniforms.uDeltaTime.value = delta * timeScaleRef.current;

    gpgpu.computation.compute();

    particlesMaterial.uniforms.uParticlesTexture.value =
      gpgpu.computation.getCurrentRenderTarget(gpgpu.particlesVariable).texture;
    particlesMaterial.uniforms.uTime.value = timeRef.current;
  });

  // Visualization helpers
  const hoverRayOriginRef = useRef();
  const hoverRayDirectionRef = useRef();
  const hoverRadiusRef = useRef();

  useEffect(() => {
    const updateHelpers = () => {
      const uniforms = gpgpu.particlesVariable.material.uniforms;
      if (hoverRayOriginRef.current) {
        hoverRayOriginRef.current.position.copy(uniforms.uMouseRayOrigin.value);
      }
      if (hoverRayDirectionRef.current) {
        const origin = uniforms.uMouseRayOrigin.value;
        const direction = uniforms.uMouseRayDirection.value
          .clone()
          .multiplyScalar(10);
        hoverRayDirectionRef.current.geometry.setFromPoints([
          origin,
          origin.clone().add(direction),
        ]);
      }
      if (hoverRadiusRef.current) {
        hoverRadiusRef.current.scale.set(
          uniforms.uMouseHoverRadius.value,
          uniforms.uMouseHoverRadius.value,
          1
        );
        hoverRadiusRef.current.position.copy(uniforms.uMouseRayOrigin.value);
      }
    };

    const interval = setInterval(updateHelpers, 100);
    return () => clearInterval(interval);
  }, [gpgpu]);

  // BV model
  const bv = useGLTF("/bv-kosteaz_new.glb");
  console.log(bv.scene);

  // We'll store a reference to the submesh we want to update the texture on:
  const frontMeshRef = useRef(null);
  useEffect(() => {
    // Identify the mesh that has the "kosteaz-bv" texture or whichever is correct
    bv.scene.traverse((child) => {
      if (
        child.isMesh &&
        child.material &&
        child.material.map &&
        child.material.map.name === "kosteaz-bv"
      ) {
        frontMeshRef.current = child;
      }
    });
  }, [bv]);

  // For uploading a new image
  const fileInputRef = useRef();
  const handleUploadClick = (e) => {
    e.preventDefault();
    fileInputRef.current?.click();
  };
  const handleImageChange = (e) => {
    const file = e.target.files?.[0];
    if (!file) return;
    const url = URL.createObjectURL(file);

    new THREE.TextureLoader().load(url, (texture) => {
      // Flip Y to fix typical texture orientation in three.js
      // If it's reversed on X, we can use negative repeat.x or rotation,
      // but let's do a simple flipY = false plus a small rotation to see if we fix it:
      texture.flipY = false;
      texture.center.set(0.5, 0.5);
      texture.repeat.set(1, 1);
      texture.rotation = 0; // rotate if needed
      texture.wrapS = THREE.ClampToEdgeWrapping;
      texture.wrapT = THREE.ClampToEdgeWrapping;

      // "Contain" logic on a 1:1 UV layout assumption
      if (texture.image) {
        const { width, height } = texture.image;
        const imgAspect = width / height;
        // We assume the card's face is "square" in UV. If it's not,
        // you might adjust faceAspect or measure the UV bounding box.
        const faceAspect = 1;
        if (imgAspect > faceAspect) {
          // image is wider => shrink horizontally
          texture.repeat.set(faceAspect / imgAspect, 1);
        } else {
          // image is taller => shrink vertically
          texture.repeat.set(1, imgAspect / faceAspect);
        }
      }

      if (frontMeshRef.current) {
        frontMeshRef.current.material.map = texture;
        frontMeshRef.current.material.needsUpdate = true;
      }
    });
  };

  /* 
    We want the BV + HTML to disappear fairly early in the scroll,
    then reappear if we scroll back up.
    We'll fade them out between scroll=0 and scroll=0.2 (approx),
    i.e. at scroll=0 => fully visible, at scroll=0.2 => fully gone.
  */
  const fadeGroupRef = useRef();
  // simple helper: fade from s=0 to s=0.2 => 1 -> 0
  function fadeHelper(s, start, end) {
    if (s <= start) return 1;
    if (s >= end) return 0;
    return 1 - (s - start) / (end - start);
  }

  const fadeFactor = fadeHelper(normalizedScroll, 0, 0.1); // 1..0
  useEffect(() => {
    if (!fadeGroupRef.current) return;
    // scale from 1 -> 0.2 for example
    const scaleValue = 0.2 + 0.8 * fadeFactor;
    fadeGroupRef.current.scale.set(scaleValue, scaleValue, scaleValue);

    // for the BV model mesh
    bv.scene.traverse((child) => {
      if (child.isMesh && child.material) {
        child.material.transparent = true;
        child.material.opacity = fadeFactor; // fade out
      }
    });

    // for the HTML, we can do the same with style, but we'll do it in a tiny re-render hack:
    // store it in a ref or state to force re-render is an option, but simpler is direct DOM:
    // we can find the <div> inside the <Html> by ID or something. We'll do a direct approach:
    // but it's simpler to handle inline style on the <Html> itself:
    // We'll do that approach below in the return (style={{ opacity: fadeFactor }})
  }, [fadeFactor, bv]);

  return (
    <>
      <group position={[0, 0, 0]} rotation={[-0.1, -0.2, 0]}>
        <points
          ref={particlesRef}
          geometry={particlesGeom}
          material={particlesMaterial}
        />
        {/* Helpers (commented out to keep them if needed) */}
        {/* 
            <mesh ref={hoverRayOriginRef}>
              <sphereGeometry args={[0.05, 16, 16]} />
              <meshBasicMaterial color="red" />
            </mesh>

            <line ref={hoverRayDirectionRef}>
              <bufferGeometry />
              <lineBasicMaterial color="green" linewidth={2} />
            </line>

            <mesh ref={hoverRadiusRef}>
              <cylinderGeometry args={[1.0, 1.0, 0.01, 32, 1]} />
              <meshBasicMaterial transparent opacity={0} side={THREE.DoubleSide} />
            </mesh>
        */}
        {/* We'll wrap the BV model + the HTML inside one group that we fade out */}
        <group ref={fadeGroupRef}>
          <group position={[-3.15, 0.2, -1.15]}>
            <PresentationControls
              global={false}
              config={{ mass: 1, tension: 500 }}
              rotation={[0, 0.3, 0]}
              polar={[-Math.PI / 2, Math.PI / 2]}
              azimuth={[-Math.PI / 1.4, Math.PI / 2]}
            >
              <Float scale={0.7} rotation-x={-0.1}>
                <primitive object={bv.scene} rotation={[-0.2, 0, 0]} />
              </Float>
              <ContactShadows
                position={[0, -1.1, 0]}
                opacity={0.75}
                scale={10}
                blur={3}
                far={4}
                color="#ffffff"
              />
            </PresentationControls>
          </group>
          {/* Example "screen" HTML */}
          <Html
            transform
            as="div"
            prepend
            wrapperClass="htmlScreen rounded-[80px]"
            occlude
            distanceFactor={0.87}
            position={[-2.22, 0.2, -1.15]}
            rotation-x={-0.2}
            style={{
              // We'll let the group scale fade out in useEffect,
              // but also apply an opacity here:
              opacity: fadeFactor,
              transition: "opacity 0.1s ease-out",
              zIndex: 0,
            }}
          >
            <div className=" p-5 rounded-[85px]">
              <div className="w-[1920px] h-[1180px] border-white border-4 border-solid  rounded-[80px]">
                <div className="service-card h-full flex flex-row rounded-[80px]">
                  <div className="w-full md:w-1/2">
                    {/* <img src="/img/kosteaz-bv-front.webp" /> */}
                  </div>
                  <div className="w-full md:w-1/2 lg:w-2/4 flex flex-col justify-center items-start z-20 p-4 lg:p-20">
                    <motion.div
                      initial={{ opacity: 0, scale: 0.5 }}
                      animate={{ opacity: 1, scale: 1 }}
                      transition={{
                        duration: 0.8,
                        delay: 0.5,
                        ease: [0, 0.71, 0.2, 1.01],
                      }}
                    >
                      <GooeyText
                        className="font-sans font-black text-white leading-[1] 
                      xl:leading-[1.05] 2xl:leading-[1.05] text-[9rem]  uppercase"
                        text="Prova il tuo testo qui!"
                      />
                      <div className="mt-4 flex flex-col lg:flex-row items-center gap-4">
                        <a
                          href="#"
                          onClick={handleUploadClick}
                          className="text-white mt-20 p-2 text-7xl px-6 border-8 rounded-2xl hover:border-white border-enoOrange-200 hover:rounded-lg hover:bg-enoOrange-200 
                        hover:rotate-1 uppercase leading-[1] transition-rotate duration-300 ease-in-out text-center w-full sm:w-auto"
                        >
                          Carica immagine
                        </a>
                        {/* Hidden file input */}
                        <input
                          ref={fileInputRef}
                          type="file"
                          style={{ display: "none" }}
                          accept="image/*"
                          onChange={handleImageChange}
                        />
                      </div>
                    </motion.div>
                  </div>
                </div>
              </div>
            </div>
          </Html>
        </group>
      </group>
    </>
  );
}

function Experience({ scrollY = 0 }) {
  const isMobile = useIsMobile();

  useGLTF.preload("../assets/laptopvertex.glb");
  useGLTF.preload("../assets/linke.glb");

  return (
    <Canvas
      gl={{
        alpha: true,
        antialias: true,
        preserveDrawingBuffer: true,
        powerPreference: "default",
        // Increase precision for better quality
        precision: "highp",
      }}
      dpr={[1, 2]} // Better handle pixel ratio
      style={{ background: "transparent", position: "fixed" }}
      camera={{
        position: [0, 5, isMobile ? 15 : 6.5],
        fov: 35,
        near: 0.1,
        far: 1000,
      }}
    >
      <directionalLight castShadow position={[1, 4, 3]} intensity={2.5} />
      <ambientLight intensity={0.5} />
      <ParticleSystem isMobile={isMobile} scrollY={scrollY} />
      <Effects>
        <ToneMapping mode={ToneMappingMode.REINHARD} />
      </Effects>
      <OrbitControls
        enableDamping={false}
        enablePan={false}
        enableRotate={false}
        enableZoom={false}
      />
      <Environment
        preset="night"
        background
        backgroundBlurriness={1}
        backgroundIntensity={0.4}
      />
    </Canvas>
  );
}

export default Experience;
