uniform float uTime;
uniform float uDeltaTime;
uniform float uVelocityScale;
uniform sampler2D uBase;
uniform sampler2D uTargetBase;
uniform float uFlowFieldInfluence;
uniform float uFlowFieldStrength;
uniform float uFlowFieldFrequency;
uniform float uNoiseScale;
uniform float uScrollStrength;
uniform float uMotionStrength;
uniform float uExplodeStrength;
uniform float uTransitionNoiseScale;
uniform vec3 uMouseRayOrigin;
uniform vec3 uMouseRayDirection;
uniform float uMouseHoverRadius;
uniform float uCursorInfluence;
uniform vec3 uClickPosition;
uniform float uClickTime;
uniform float uExplosionFactor;
uniform float uModel2Factor;

#include ../includes/simplexNoise4d.glsl

void main() {
    vec2 uv = gl_FragCoord.xy / resolution.xy;
    vec4 particle = texture2D(uParticles, uv);
    vec4 basePos = texture2D(uBase, uv);
    vec4 targetPos = texture2D(uTargetBase, uv);

    if (particle.w == 0.0) {
        particle.xyz = basePos.xyz;
        particle.w = 0.5;
    }

    float dt = uDeltaTime;
    float time = uTime;

    // Global flow field
    vec4 nInput = vec4(particle.xyz * uFlowFieldFrequency, time);
    float nx = simplexNoise4d(nInput);
    float ny = simplexNoise4d(nInput + vec4(1.23, 2.34, 3.45, 4.56));
    float nz = simplexNoise4d(nInput + vec4(2.13, 1.11, 0.42, 3.33));
    vec3 flowDir = normalize(vec3(nx, ny, nz));
    float flowMult = (uFlowFieldStrength + uScrollStrength) * uFlowFieldInfluence;
    vec3 flowForce = flowDir * flowMult;

    // Hover-specific swirl
    vec3 p = particle.xyz;
    vec3 O = uMouseRayOrigin;
    vec3 D = normalize(uMouseRayDirection);
    float t = dot((p - O), D);
    vec3 closest = O + t * D;
    float distToLine = length(p - closest);
    float influence = smoothstep(uMouseHoverRadius, 0.0, distToLine);

    // Rotation axis
    vec3 toCenter = normalize(p - closest);
    vec3 hoverUp = normalize(cross(D, vec3(0.0, 1.0, 0.0)));
    vec3 rotationAxis = cross(toCenter, hoverUp);

    // Add time-based turbulence
    float hoverNoiseScale = 2.0;
    vec4 hoverNoise = vec4((p - closest) * hoverNoiseScale, time * 0.5);
    float hoverTurbulence = simplexNoise4d(hoverNoise) * 0.5 + 0.5;

    // Combine rotation + turbulence
    vec3 hoverFlow = normalize(rotationAxis + flowDir * hoverTurbulence);
    float hoverStrength = influence * uCursorInfluence * 2.0;
    vec3 hoverForce = (hoverFlow * 0.2) * hoverStrength;

    // Explosion factor
    float scatterAmplitude = 20.0;
    vec3 scatterOffset = flowDir * scatterAmplitude * uExplosionFactor;
    vec3 shapePos = basePos.xyz;
    vec3 scatteredPos = mix(shapePos, shapePos + scatterOffset, uExplosionFactor);
    vec3 finalPos = mix(scatteredPos, targetPos.xyz, uModel2Factor);

    float shapePullStrength = 4.0;
    vec3 toFinal = (finalPos - p) * shapePullStrength;

    // Click-based blast
    vec3 toClick = p - uClickPosition;
    float distClick = length(toClick);
    float blast = exp(-distClick * 1.8) * uExplodeStrength;
    vec3 blastForce = normalize(toClick) * blast;

    // Sum of all forces
    vec3 totalForce = vec3(0.0);
    totalForce += flowForce * uMotionStrength;
    totalForce += toFinal * uMotionStrength;
    totalForce += blastForce;
    totalForce += hoverForce;

    particle.xyz += totalForce * dt * uVelocityScale;

    // Some usage of w
    particle.w = mod(particle.w + dt * 0.2, 1.0);

    gl_FragColor = particle;
}
