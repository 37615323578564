// AboutUs.jsx
import React from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { FaTripadvisor } from "react-icons/fa";
import { IoStar, IoStarHalf } from "react-icons/io5";
import { FcGoogle } from "react-icons/fc";

function AboutUs() {
  const { t } = useTranslation("global");

  return (
    <main className="bg-background-light font-body text-center md:text-left overflow-x-hidden scroll-smooth">
      {/* Hero Section */}
      <section className="relative w-full h-[93vh] md:h-dvh bg-[url('../img/in6.webp')] bg-center bg-cover flex items-center justify-center">
        <div className="absolute inset-0 bg-gradient-to-b from-secondary-200/60 to-tertiary-200/60"></div>
        <motion.div
          className="relative z-10 text-center px-6 md:px-12 lg:px-20 flex flex-col justify-center"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <h1 className="font-heading text-white text-5xl md:text-6xl lg:text-8xl mb-6">
            {t("aboutUs.title")}
          </h1>
          <p className="text-white mb-8 max-w-2xl mx-auto md:text-lg lg:text-xl">
            {t("aboutUs.subtitle")}
          </p>
        </motion.div>
      </section>

      {/* About Casa Dipinta (Short) */}
      <section className="py-24 max-w-8xl mx-auto px-6 md:px-12 mx-a lg:px-20 scroll-snap-align start">
        <motion.div
          className="mx-auto grid grid-cols-1 xl:grid-cols-2 gap-16 items-center"
          initial={{ opacity: 0, y: 40 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
        >
          <div>
            <h2 className="text-7xl mb-10 font-logo font-thin text-secondary-200">
              {t("home.about.title")}
            </h2>
            <h3 className="text-3xl mb-3 font-heading text-secondary-200">
              {t("home.about.where")}
            </h3>
            <p className="text-secondary-200 mb-6  text-center md:text-left">
              {t("home.about.introShort")}
            </p>
          </div>
          <div className="relative">
            <img
              src="../img/palazzoercole-credits.webp"
              alt="Palazzo Ercole Spilimbergo"
              className="w-full h-auto object-cover shadow-lg"
            />
            <p className="text-white text-xs absolute bottom-2 right-2 z-40 underline hover:bg-secondary-200/50 p-1 hover:text-background-light transition">
              photo:{" "}
              <a href="www.vivispilimbergo.it" target="_blank" rel="noreferrer">
                www.vivispilimbergo.it
              </a>
            </p>
          </div>
        </motion.div>
      </section>

      {/* Our rewards */}
      <section className="py-24 max-w-8xl  mx-auto px-6 md:px-12 gap-16  flex  flex-col xl:flex-row-reverse items-center lg:px-20">
        <div className="w-full xl:w-1/2">
          <motion.h2
            className="text-4xl mb-10 font-heading text-secondary-200 text-center xl:text-left"
            initial={{ opacity: 0, y: 40 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          >
            {t("aboutUs.rewards.title")}
          </motion.h2>

          <motion.p
            className="text-secondary-200 mb-8 max-w-3xl text-center xl:text-left"
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          >
            {t("aboutUs.rewards.message")}
          </motion.p>

          {/* Review indicators */}
          <div className="flex flex-col 2xl:flex-row mx-auto items-center xl:items-start mb-0 lg:mb-5">
            <motion.div
              className="w-fit px-5 h-9 lg:h-12 flex flex-row items-center text-center mb-0 lg:mb-1"
              initial={{ opacity: 0, x: 50 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
            >
              <FaTripadvisor className="text-2xl text-[#35e0a1]" />
              <a
                href="https://www.tripadvisor.it/Restaurant_Review-g790405-d4922962-Reviews-Casa_Dipinta-Spilimbergo_Province_of_Pordenone_Friuli_Venezia_Giulia.html"
                target="_blank"
                rel="noreferrer"
                className="flex flex-row items-center text-secondary-200 pl-2"
              >
                <p className="mr-2">{t("aboutUs.rewards.tripAdvisorScore")}</p>
                <IoStar />
                <IoStar />
                <IoStar />
                <IoStar />
                <IoStarHalf />
                <p className="ml-2">
                  {t("aboutUs.rewards.tripAdvisorReviews")}
                </p>
              </a>
            </motion.div>

            <motion.div
              className="w-fit px-5 h-9 lg:h-12 flex flex-row items-center text-center mb-0 lg:mb-2"
              initial={{ opacity: 0, x: -50 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
            >
              <FcGoogle className="text-2xl" />
              <a
                href="https://www.google.it/search?q=casa+dipinta+spilimbergo"
                target="_blank"
                rel="noreferrer"
                className="flex flex-row items-center text-secondary-200 pl-2"
              >
                <p className="mr-2">{t("aboutUs.rewards.googleScore")}</p>
                <IoStar />
                <IoStar />
                <IoStar />
                <IoStar />
                <IoStarHalf />
                <p className="ml-2 mr-2">
                  {t("aboutUs.rewards.googleReviews")}
                </p>
              </a>
            </motion.div>
          </div>
          {/* Button to leave a review */}
          <motion.div
            className="mt-8 w-fit mx-auto xl:mx-0"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          >
            <a
              target="_blank"
              href="https://www.tripadvisor.it/UserReviewEdit-g790405-d4922962-Casa_Dipinta-Spilimbergo_Province_of_Pordenone_Friuli_Venezia_Giulia.html"
              className="text-secondary-400 p-4 border border-secondary-400 hover:bg-secondary-400 hover:text-white transition-colors duration-300 ease-custom-easing"
            >
              {t("aboutUs.rewards.button")}
            </a>
          </motion.div>
        </div>
        {/* Ribbons / Awards */}
        <a
          href="https://restaurantguru.it/Casa-Dipinta-Spilimbergo"
          target="_blank"
          rel="noreferrer"
          className="grid mx-auto w-full xl:w-1/2 grid-cols-2 "
        >
          <img
            className="hover:scale-[0.85] transition-transform duration-300 ease-custom-easing scale-90"
            src="../img/ribbon1.webp"
            alt="ribbon1"
          />
          <img
            className="hover:scale-[0.85] transition-transform duration-300 ease-custom-easing"
            src="../img/ribbon2.webp"
            alt="ribbon2"
          />
          <img
            className="hover:scale-[0.85] transition-transform duration-300 ease-custom-easing scale-90"
            src="../img/ribbon3.webp"
            alt="ribbon3"
          />
          <img
            className="hover:scale-[0.85] transition-transform duration-300 ease-custom-easing"
            src="../img/ribbon4.webp"
            alt="ribbon4"
          />
        </a>
      </section>

      {/* Storia di Casa Dipinta */}
      <section className="py-24 px-6 md:px-12 lg:px-20">
        <motion.div
          className="max-w-8xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-16 items-center"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1 }}
        >
          <div>
            <h2 className="text-5xl md:text-7xl mb-5 font-heading text-secondary-200">
              {t("aboutUs.history.title")}
            </h2>
            <p className="text-secondary-200 mb-6">
              {t("aboutUs.history.content")}
            </p>
          </div>
          <motion.div
            className="relative"
            initial={{ opacity: 0, x: 50 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 1 }}
          >
            <img
              src="../img/micheleCasaDipinta.webp"
              alt="Casa Dipinta History"
              className="w-full h-auto object-cover shadow-lg"
            />
          </motion.div>
        </motion.div>
      </section>

      {/* Sezione Chef */}
      <section className="py-24 px-6 md:px-12 lg:px-20 bg-secondary-200 text-background-light">
        <motion.div
          className="max-w-8xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-16 items-center"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1 }}
        >
          <motion.div
            className="relative"
            initial={{ opacity: 0, x: -50 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 1 }}
          >
            <img
              src="../img/michele.webp"
              alt="Chef Michele Bellotto"
              className="w-full aspect-square object-cover shadow-lg"
            />
          </motion.div>
          <div>
            <h2 className="text-5xl md:text-7xl mb-5 font-heading">
              {t("aboutUs.chef.title")}
            </h2>
            <p className="mb-6">{t("aboutUs.chef.bio")}</p>
            <p className="mb-6">{t("aboutUs.chef.message")}</p>
          </div>
        </motion.div>
      </section>

      {/* Valori di Casa Dipinta */}
      <section className="py-24 px-6 md:px-12 lg:px-20 bg-background-light">
        <motion.div
          className="max-w-8xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-16 text-center"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1 }}
        >
          <div>
            <h3 className="text-3xl mb-3 font-heading text-secondary-200">
              {t("aboutUs.values.fresh.title")}
            </h3>
            <p className="text-secondary-200">
              {t("aboutUs.values.fresh.content")}
            </p>
          </div>
          <div>
            <h3 className="text-3xl mb-3 font-heading text-secondary-200">
              {t("aboutUs.values.authentic.title")}
            </h3>
            <p className="text-secondary-200">
              {t("aboutUs.values.authentic.content")}
            </p>
          </div>
          <div>
            <h3 className="text-3xl mb-3 font-heading text-secondary-200">
              {t("aboutUs.values.welcome.title")}
            </h3>
            <p className="text-secondary-200">
              {t("aboutUs.values.welcome.content")}
            </p>
          </div>
        </motion.div>
      </section>
    </main>
  );
}

export default AboutUs;
