import React from "react";
import { FaChevronDown } from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";

const FAQ = React.memo(({ question, answer, isOpen, onToggle, className }) => {
  return (
    <motion.div layout className={`${className} mb-6`}>
      <button
        onClick={onToggle}
        className={`w-full flex cursor-pointer justify-between items-center text-left font-sans text-xl md:text-2xl py-4 focus:outline-none transition-colors duration-300 ${
          isOpen ? "text-enoOrange-200" : "text-white"
        }`}
      >
        <span>{question}</span>
        <motion.span
          animate={{ rotate: isOpen ? 180 : 0 }}
          transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
        >
          <FaChevronDown size={24} />
        </motion.span>
      </button>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto", marginTop: "0.5rem" },
              collapsed: { opacity: 0, height: 0, marginTop: "0" },
            }}
            transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
            className="overflow-hidden text-white font-serif text-base md:text-lg"
          >
            <div className="p-2">{answer}</div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
});

export default FAQ;
