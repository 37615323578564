import React, { useRef, useEffect, useMemo } from "react";
import { extend, useFrame, useThree } from "@react-three/fiber";
import { useControls } from "leva";
import * as THREE from "three";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass";
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass";
import { OutputPass } from "three/examples/jsm/postprocessing/OutputPass";
import { SMAAPass } from "three/examples/jsm/postprocessing/SMAAPass";

// Make components available to R3F
extend({ EffectComposer, RenderPass, UnrealBloomPass, OutputPass, SMAAPass });

const Effects = ({ children }) => {
  // Get Three.js instances
  const { scene, camera, gl, size } = useThree();

  // Refs for our passes
  const composer = useRef();
  const bloomPass = useRef();
  const smaaPass = useRef();

  // Track previous size for resize optimization
  const previousSize = useRef({ width: 0, height: 0 });

  // Debounce resize operations
  const resizeTimeoutRef = useRef(null);

  // Refined bloom controls with better defaults
  const { enabled, strength, radius, threshold, samples, useSmaa } =
    useControls("Post Processing", {
      enabled: { value: true },
      strength: { value: 0.35, min: 0, max: 2, step: 0.01 },
      radius: { value: 0.2, min: 0, max: 1, step: 0.01 },
      threshold: { value: 0.85, min: 0, max: 1, step: 0.01 },
      samples: { value: 16, min: 2, max: 32, step: 1 },
      useSmaa: { value: true },
    });

  // Calculate safe dimensions based on device capabilities
  const calculateSafeDimensions = useMemo(
    () => (width, height, gl) => {
      const maxSize = Math.min(gl.capabilities.maxTextureSize, 1080);
      const pixelRatio = Math.min(window.devicePixelRatio || 1, 2);

      let newWidth = Math.round(width * pixelRatio);
      let newHeight = Math.round(height * pixelRatio);

      // Enforce minimum dimensions
      newWidth = Math.max(2, newWidth);
      newHeight = Math.max(2, newHeight);

      // Scale down if exceeds maximum
      if (newWidth > maxSize || newHeight > maxSize) {
        const ratio = width / height;
        if (ratio > 1) {
          newWidth = maxSize;
          newHeight = Math.round(maxSize / ratio);
        } else {
          newHeight = maxSize;
          newWidth = Math.round(maxSize * ratio);
        }
      }

      // Ensure even numbers
      newWidth = Math.floor(newWidth / 2) * 2;
      newHeight = Math.floor(newHeight / 2) * 2;

      return { width: newWidth, height: newHeight };
    },
    []
  );

  // Initialize composer and passes
  useEffect(() => {
    if (!gl || !scene || !camera || !enabled) return;

    try {
      // Create high-quality render target
      const renderTarget = new THREE.WebGLRenderTarget(
        size.width * gl.getPixelRatio(),
        size.height * gl.getPixelRatio(),
        {
          minFilter: THREE.LinearFilter,
          magFilter: THREE.LinearFilter,
          format: THREE.RGBAFormat,
          encoding: THREE.sRGBEncoding,
          type: THREE.HalfFloatType,
          stencilBuffer: false,
          samples: Math.min(gl.capabilities.maxSamples, samples),
        }
      );

      // Initialize composer
      composer.current = new EffectComposer(gl, renderTarget);
      const { width, height } = calculateSafeDimensions(
        size.width,
        size.height,
        gl
      );
      composer.current.setSize(width, height);
      composer.current.setPixelRatio(Math.min(gl.getPixelRatio(), 2));

      // Add render pass
      const renderPass = new RenderPass(scene, camera);
      composer.current.addPass(renderPass);

      // Add bloom pass
      const bloomPassInstance = new UnrealBloomPass(
        new THREE.Vector2(width, height),
        strength,
        radius,
        threshold
      );
      bloomPass.current = bloomPassInstance;
      composer.current.addPass(bloomPassInstance);

      // Add SMAA pass if enabled
      if (useSmaa) {
        const smaaPassInstance = new SMAAPass(width, height);
        smaaPass.current = smaaPassInstance;
        composer.current.addPass(smaaPassInstance);
      }

      // Add output pass
      const outputPass = new OutputPass();
      composer.current.addPass(outputPass);

      previousSize.current = { width, height };

      // Cleanup function
      return () => {
        renderTarget.dispose();
        composer.current?.dispose();
        bloomPass.current?.dispose();
        smaaPass.current?.dispose();
      };
    } catch (error) {
      console.warn("Failed to initialize effect composer:", error);
    }
  }, [gl, scene, camera, size.width, size.height, enabled, samples, useSmaa]);

  // Update bloom parameters
  useEffect(() => {
    if (!bloomPass.current || !enabled) return;
    try {
      bloomPass.current.strength = strength;
      bloomPass.current.radius = radius;
      bloomPass.current.threshold = threshold;
    } catch (error) {
      console.warn("Failed to update bloom parameters:", error);
    }
  }, [strength, radius, threshold, enabled]);

  // Handle resize with debounce
  useEffect(() => {
    if (!composer.current || !enabled) return;

    const handleResize = () => {
      try {
        const { width, height } = calculateSafeDimensions(
          size.width,
          size.height,
          gl
        );

        // Only update if size changed
        if (
          width !== previousSize.current.width ||
          height !== previousSize.current.height
        ) {
          composer.current.setSize(width, height);
          bloomPass.current?.setSize(width, height);
          smaaPass.current?.setSize(width, height);
          previousSize.current = { width, height };
        }
      } catch (error) {
        console.warn("Failed to handle resize:", error);
      }
    };

    // Debounce resize
    if (resizeTimeoutRef.current) clearTimeout(resizeTimeoutRef.current);
    resizeTimeoutRef.current = setTimeout(handleResize, 200);

    return () => {
      if (resizeTimeoutRef.current) clearTimeout(resizeTimeoutRef.current);
    };
  }, [size.width, size.height, gl, enabled]);

  // Render loop
  useFrame(() => {
    if (!composer.current?.render || !enabled) return;
    try {
      composer.current.render();
    } catch (error) {
      console.warn("Render failed:", error);
      // Attempt recovery by reinitializing at minimum size
      try {
        const minSize = calculateSafeDimensions(1, 1, gl);
        composer.current.setSize(minSize.width, minSize.height);
        bloomPass.current?.setSize(minSize.width, minSize.height);
        smaaPass.current?.setSize(minSize.width, minSize.height);
      } catch (recoveryError) {
        console.error("Failed to recover from render error:", recoveryError);
      }
    }
  }, 1);

  return children;
};

export default Effects;
