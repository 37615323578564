import React from "react";
import { motion } from "framer-motion";

function PrivacyPolicy() {
  return (
    <main className="bg-background-light font-body text-center md:text-left scroll-smooth px-6 md:px-12 lg:px-24">
      <motion.div
        className="max-w-3xl mx-auto py-24"
        initial={{ opacity: 0, y: 40 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.5 }}
      >
        <h1 className="text-5xl md:text-7xl mb-10 font-heading font-bold text-secondary-200">
          Informativa Privacy
        </h1>

        <section className="mb-12 text-left">
          <h2 className="text-3xl md:text-5xl mb-4 font-semibold text-secondary-200">
            INFORMATIVA PRIVACY ai sensi del Regolamento (UE) 2016/679 (GDPR)
          </h2>
          <p className="text-secondary-200 mb-4">
            Documento informativo ai sensi dell’articolo 13 del Regolamento (UE)
            2016/679 (GDPR) – Informativa per il trattamento di dati personali
            raccolti presso l’interessato
          </p>
          <p className="text-secondary-200">
            Nel rispetto di quanto previsto dal Regolamento (UE) 2016/679
            (Regolamento Europeo per la protezione dei dati personali), si
            forniscono le dovute informazioni in ordine al trattamento dei dati
            personali forniti.
          </p>
        </section>

        <section className="mb-12 text-left">
          <h3 className="text-2xl md:text-4xl mb-4 font-semibold text-secondary-200">
            Definizioni
          </h3>
          <ul className="list-disc list-inside text-secondary-200 space-y-2">
            <li>
              <strong>Dato personale:</strong> qualsiasi informazione
              riguardante una persona fisica identificata o identificabile
              (“interessato”).
            </li>
            <li>
              <strong>Interessato:</strong> la persona fisica cui si riferiscono
              i dati personali.
            </li>
            <li>
              <strong>Titolare del trattamento:</strong> la persona fisica o
              giuridica, autorità pubblica, servizio o altro organismo che,
              singolarmente o insieme ad altri, determina le finalità e i mezzi
              del trattamento di dati personali.
            </li>
            <li>
              <strong>Responsabile del trattamento:</strong> la persona fisica o
              giuridica, autorità pubblica, servizio o altro organismo che
              tratta dati personali per conto del titolare del trattamento.
            </li>
            <li>
              <strong>Consenso:</strong> qualsiasi manifestazione di volontà
              libera, specifica, informata e inequivocabile con cui
              l’interessato accetta, mediante dichiarazione o azione positiva
              inequivocabile, che i dati personali che lo riguardano siano
              oggetto di trattamento.
            </li>
          </ul>
        </section>

        <section className="mb-12 text-left">
          <h3 className="text-2xl md:text-4xl mb-4 font-semibold text-secondary-200">
            1. Titolare del Trattamento dei Dati
          </h3>
          <p className="text-secondary-200 mb-4">
            Il Titolare del Trattamento dei Dati personali è:
          </p>
          <ul className="list-none text-secondary-200 space-y-1">
            <li>
              <strong>Nome:</strong> BELLOTTO MICHELE
            </li>
            <li>
              <strong>Partita IVA:</strong> 01931210932
            </li>
            <li>
              <strong>Codice Fiscale:</strong> BLLMHL85P04I904Z
            </li>
            <li>
              <strong>Ragione Sociale:</strong> BELLOTTO MICHELE
            </li>
            <li>
              <strong>Indirizzo:</strong> VIA PIAVE 1, 33097 SPILIMBERGO (PN)
            </li>
            <li>
              <strong>Rea:</strong> 366433
            </li>
            <li>
              <strong>PEC:</strong>{" "}
              <a
                href="mailto:bellotto.michele@pec.it"
                className="underline text-secondary-400 hover:text-secondary-500"
              >
                bellotto.michele@pec.it
              </a>
            </li>
            <li>
              <strong>Email:</strong>{" "}
              <a
                href="mailto:info@casadipinta.com"
                className="underline text-secondary-400 hover:text-secondary-500"
              >
                info@casadipinta.com
              </a>
            </li>
            <li>
              <strong>Telefono:</strong> +39 0427 41697
            </li>
          </ul>
        </section>

        <section className="mb-12 text-left">
          <h3 className="text-2xl md:text-4xl mb-4 font-semibold text-secondary-200">
            2. Finalità e Base Giuridica del Trattamento
          </h3>
          <p className="text-secondary-200 mb-4">
            I dati personali forniti saranno trattati nel rispetto delle
            seguenti condizioni di liceità ai sensi dell’art. 6 del Regolamento
            (UE) 2016/679 per le seguenti finalità:
          </p>
          <ul className="list-disc list-inside text-secondary-200 space-y-2">
            <li>
              <strong>Navigazione sul sito web:</strong>
              <ul className="list-disc list-inside pl-5">
                <li>
                  <strong>Finalità:</strong> Monitorare e analizzare il traffico
                  del sito web per ottimizzare l’esperienza utente e le
                  strategie di marketing.
                </li>
                <li>
                  <strong>Base giuridica:</strong> Legittimo interesse (art. 6,
                  par. 1, lett. f)).
                </li>
              </ul>
            </li>
            <li>
              <strong>
                Compilazione del modulo di contatto per richiesta di
                informazioni o prenotazioni:
              </strong>
              <ul className="list-disc list-inside pl-5">
                <li>
                  <strong>Finalità:</strong> Gestire le richieste di contatto e
                  prenotazione, fornire informazioni richieste, confermare
                  prenotazioni.
                </li>
                <li>
                  <strong>Base giuridica:</strong> Esecuzione di misure
                  precontrattuali (art. 6, par. 1, lett. b)) e esecuzione del
                  contratto (art. 6, par. 1, lett. b)).
                </li>
              </ul>
            </li>
            <li>
              <strong>
                Compilazione del modulo per pagamenti online (se applicabile):
              </strong>
              <ul className="list-disc list-inside pl-5">
                <li>
                  <strong>Finalità:</strong> Eseguire pagamenti online per
                  servizi offerti.
                </li>
                <li>
                  <strong>Base giuridica:</strong> Esecuzione del contratto
                  (art. 6, par. 1, lett. b)).
                </li>
              </ul>
            </li>
            <li>
              <strong>Comunicazioni commerciali (previo consenso):</strong>
              <ul className="list-disc list-inside pl-5">
                <li>
                  <strong>Finalità:</strong> Invio di newsletter, promozioni e
                  offerte speciali.
                </li>
                <li>
                  <strong>Base giuridica:</strong> Consenso dell’interessato
                  (art. 6, par. 1, lett. a)).
                </li>
              </ul>
            </li>
            <li>
              <strong>
                Gestione delle iscrizioni a newsletter (se applicabile):
              </strong>
              <ul className="list-disc list-inside pl-5">
                <li>
                  <strong>Finalità:</strong> Informare gli iscritti su novità,
                  eventi e offerte del ristorante.
                </li>
                <li>
                  <strong>Base giuridica:</strong> Consenso dell’interessato
                  (art. 6, par. 1, lett. a)).
                </li>
              </ul>
            </li>
          </ul>
        </section>

        <section className="mb-12 text-left">
          <h3 className="text-2xl md:text-4xl mb-4 font-semibold text-secondary-200">
            3. Tipologia di Dati Trattati
          </h3>
          <p className="text-secondary-200 mb-4">
            Il trattamento dei dati personali riguarda le seguenti categorie di
            dati:
          </p>
          <ul className="list-disc list-inside text-secondary-200 space-y-2 pl-5">
            <li>
              Dati identificativi: nome, cognome, indirizzo email, numero di
              telefono.
            </li>
            <li>Dati di contatto: indirizzo, CAP, città.</li>
            <li>
              Dati di prenotazione: data e orario della prenotazione, numero di
              persone, eventuali richieste particolari.
            </li>
            <li>
              Messaggi e comunicazioni: contenuti inviati tramite il modulo di
              contatto.
            </li>
            <li>
              Dati tecnici: indirizzo IP, tipo di browser, sistema operativo,
              pagine visitate, durata della visita (raccolti tramite strumenti
              di analytics come Google Analytics).
            </li>
            <li>
              Dati per pagamenti online (se applicabile): informazioni relative
              al pagamento effettuato.
            </li>
          </ul>
        </section>

        <section className="mb-12 text-left">
          <h3 className="text-2xl md:text-4xl mb-4 font-semibold text-secondary-200">
            4. Destinatari o Categorie di Destinatari dei Dati
          </h3>
          <p className="text-secondary-200 mb-4">
            I dati personali forniti saranno comunicati a destinatari, che
            tratteranno i dati in qualità di responsabili (art. 28 del
            Regolamento (UE) 2016/679) e/o in qualità di persone fisiche che
            agiscono sotto l’autorità del Titolare e del Responsabile (art. 29
            del Regolamento (UE) 2016/679), per le finalità sopra elencate.
          </p>
          <p className="text-secondary-200 mb-4">
            A titolo esemplificativo e non esaustivo, i dati saranno comunicati
            a:
          </p>
          <ul className="list-disc list-inside text-secondary-200 space-y-2 pl-5">
            <li>
              Soggetti che forniscono servizi per la gestione del sistema
              informativo: ad esempio, provider di hosting e servizi IT.
            </li>
            <li>
              Servizi di analytics: come Google Analytics, per l’analisi del
              traffico e delle performance del sito.
            </li>
            <li>
              Fornitori di servizi per la gestione delle prenotazioni: qualora
              si utilizzassero piattaforme esterne.
            </li>
            <li>
              Professionisti e consulenti: nell’ambito di rapporti di assistenza
              e consulenza.
            </li>
            <li>
              Autorità competenti: per adempimenti di obblighi di legge e/o di
              disposizioni di organi pubblici, su richiesta.
            </li>
          </ul>
          <p className="text-secondary-200 mb-4">
            I soggetti appartenenti alle categorie suddette svolgono la funzione
            di Responsabile del trattamento dei dati o operano in totale
            autonomia come distinti Titolari del trattamento.
          </p>
          <p className="text-secondary-200">
            L’elenco dei Responsabili del trattamento è costantemente aggiornato
            e disponibile presso la sede legale del Titolare del trattamento in
            VIA PIAVE 1, 33097 SPILIMBERGO (PN).
          </p>
        </section>

        <section className="mb-12 text-left">
          <h3 className="text-2xl md:text-4xl mb-4 font-semibold text-secondary-200">
            5. Trasferimento dei Dati verso Paesi Terzi e/o Organizzazioni
            Internazionali e Garanzie
          </h3>
          <p className="text-secondary-200 mb-4">
            I dati personali possono essere trasferiti verso Paesi appartenenti
            all’Unione Europea e in Paesi Extra UE in caso di necessità per
            ottemperare alle finalità sopra indicate. I trasferimenti avverranno
            nel rispetto delle condizioni di cui al Capo V – Trasferimenti di
            dati personali verso paesi terzi o organizzazioni internazionali –
            del Regolamento (UE) 2016/679, garantendo un adeguato livello di
            protezione dei dati.
          </p>
          <p className="text-secondary-200 mb-4">
            L’interessato potrà ottenere informazioni circa l’effettivo
            trasferimento dei dati verso un paese terzo e le idonee garanzie
            adottate scrivendo una mail a{" "}
            <a
              href="mailto:info@casadipinta.com"
              className="underline text-secondary-400 hover:text-secondary-500"
            >
              info@casadipinta.com
            </a>
            .
          </p>
        </section>

        <section className="mb-12 text-left">
          <h3 className="text-2xl md:text-4xl mb-4 font-semibold text-secondary-200">
            6. Periodo di Conservazione dei Dati o Criteri per Determinare il
            Periodo
          </h3>
          <p className="text-secondary-200 mb-4">
            I dati personali saranno conservati per il tempo necessario a
            conseguire le finalità per le quali sono stati raccolti e, comunque,
            in conformità con le disposizioni di legge vigenti. In particolare:
          </p>
          <ul className="list-disc list-inside text-secondary-200 space-y-2 pl-5">
            <li>
              Dati di navigazione: Conservati secondo quanto previsto dalla{" "}
              <a
                href="[Inserire link alla Cookie Policy]"
                className="underline text-secondary-400 hover:text-secondary-500"
              >
                Cookie Policy
              </a>
              .
            </li>
            <li>
              Richiesta di contatto e prenotazione: Conservati per un periodo di
              24 mesi dalla data dell’ultima interazione.
            </li>
            <li>
              Dati per pagamenti online (se applicabile): Conservati per il
              periodo minimo richiesto dalla legge in relazione al regime
              contabile/fiscale adottato dal Titolare.
            </li>
            <li>
              Consensi per comunicazioni commerciali: Conservati fino alla
              revoca del consenso.
            </li>
          </ul>
          <p className="text-secondary-200">
            Successivamente, i dati saranno cancellati in modo sicuro o
            anonimizzati.
          </p>
        </section>

        <section className="mb-12 text-left">
          <h3 className="text-2xl md:text-4xl mb-4 font-semibold text-secondary-200">
            7. Natura del Conferimento dei Dati e Conseguenze del Rifiuto di
            Fornire i Dati
          </h3>
          <p className="text-secondary-200 mb-4">
            La comunicazione dei dati personali non è un obbligo, salvo quanto
            specificato per i dati di navigazione. Tuttavia, la fornitura dei
            dati è necessaria per adempiere alle finalità del trattamento. Il
            mancato conferimento dei dati personali comporta l’impossibilità di
            fruire dei servizi offerti dal Titolare del trattamento.
          </p>
        </section>

        <section className="mb-12 text-left">
          <h3 className="text-2xl md:text-4xl mb-4 font-semibold text-secondary-200">
            8. Diritti degli Interessati
          </h3>
          <p className="text-secondary-200 mb-4">
            L’interessato ha il diritto di:
          </p>
          <ul className="list-disc list-inside text-secondary-200 space-y-2 pl-5">
            <li>Accedere ai propri dati personali.</li>
            <li>Richiedere la rettifica o l’aggiornamento dei propri dati.</li>
            <li>
              Richiedere la cancellazione dei dati personali (“diritto
              all’oblio”).
            </li>
            <li>Richiedere la limitazione del trattamento dei dati.</li>
            <li>
              Opporsi al trattamento dei propri dati personali per motivi
              legittimi.
            </li>
            <li>
              Richiedere la portabilità dei dati a un altro titolare del
              trattamento.
            </li>
            <li>
              Revocare il consenso prestato in qualsiasi momento, senza
              pregiudicare la liceità del trattamento basata sul consenso
              prestato prima della revoca.
            </li>
            <li>
              Proporre reclamo all’Autorità di controllo competente (Garante per
              la protezione dei dati personali).
            </li>
          </ul>
          <p className="text-secondary-200 mb-4">
            Per esercitare tali diritti, l’interessato può inviare una richiesta
            scritta al Titolare del Trattamento all’indirizzo email{" "}
            <a
              href="mailto:info@casadipinta.com"
              className="underline text-secondary-400 hover:text-secondary-500"
            >
              info@casadipinta.com
            </a>{" "}
            o all’indirizzo fisico sopra indicato.
          </p>
        </section>

        <section className="mb-12 text-left">
          <h3 className="text-2xl md:text-4xl mb-4 font-semibold text-secondary-200">
            9. Processi Decisionali Automatizzati
          </h3>
          <p className="text-secondary-200">
            Non sono previsti processi decisionali automatizzati, inclusa la
            profilazione, che producano effetti giuridici o analoghi sugli
            interessati.
          </p>
        </section>

        <section className="mb-12 text-left">
          <h3 className="text-2xl md:text-4xl mb-4 font-semibold text-secondary-200">
            10. Sicurezza dei Dati
          </h3>
          <p className="text-secondary-200 mb-4">
            Il Titolare adotta misure tecniche e organizzative adeguate per
            garantire un livello di sicurezza adeguato al rischio, incluse:
          </p>
          <ul className="list-disc list-inside text-secondary-200 space-y-2 pl-5">
            <li>Crittografia dei dati sensibili.</li>
            <li>
              Accesso limitato ai dati personali solo al personale autorizzato.
            </li>
            <li>Backup periodici dei dati.</li>
            <li>
              Aggiornamento costante dei sistemi di sicurezza informatica.
            </li>
            <li>
              Formazione del personale sul trattamento sicuro dei dati
              personali.
            </li>
            <li>
              Protezione contro accessi non autorizzati, perdite, distruzioni o
              danni accidentali dei dati.
            </li>
          </ul>
        </section>

        <section className="mb-12 text-left">
          <h3 className="text-2xl md:text-4xl mb-4 font-semibold text-secondary-200">
            11. Categorie di Destinatari dei Dati
          </h3>
          <p className="text-secondary-200 mb-4">
            I dati personali possono essere comunicati a soggetti terzi che
            svolgono attività strumentali alle finalità del trattamento, quali:
          </p>
          <ul className="list-disc list-inside text-secondary-200 space-y-2 pl-5">
            <li>
              Provider di servizi IT e hosting: per garantire il funzionamento
              del sito web.
            </li>
            <li>
              Servizi di analytics: come Google Analytics, per l’analisi del
              traffico e delle performance del sito.
            </li>
            <li>
              Fornitori di servizi per la gestione delle prenotazioni: qualora
              si utilizzassero piattaforme esterne.
            </li>
            <li>
              Professionisti e consulenti: nell’ambito di rapporti di assistenza
              e consulenza.
            </li>
          </ul>
          <p className="text-secondary-200 mb-4">
            Tutti i soggetti destinatari sono tenuti a trattare i dati in
            conformità con le disposizioni del GDPR e a non utilizzarli per
            finalità diverse da quelle indicate.
          </p>
        </section>

        <section className="mb-12 text-left">
          <h3 className="text-2xl md:text-4xl mb-4 font-semibold text-secondary-200">
            12. Utilizzo di Strumenti di Analytics
          </h3>
          <p className="text-secondary-200 mb-4">
            Il sito utilizza strumenti di analytics (es. Google Analytics) per
            raccogliere e analizzare dati sull’utilizzo del sito web. Questi
            strumenti utilizzano cookie e tecnologie simili per tracciare le
            attività degli utenti. I dati raccolti sono anonimizzati e
            utilizzati esclusivamente per migliorare l’esperienza utente e le
            strategie di marketing del ristorante.
          </p>
          <p className="text-secondary-200">
            Per maggiori dettagli sull’uso dei cookie e su come gestirli, si
            invita a consultare la{" "}
            <a
              href="[Inserire link alla Cookie Policy]"
              className="underline text-secondary-400 hover:text-secondary-500"
            >
              Cookie Policy
            </a>
            .
          </p>
        </section>

        <section className="mb-12 text-left">
          <h3 className="text-2xl md:text-4xl mb-4 font-semibold text-secondary-200">
            13. Modalità di Raccolta dei Dati
          </h3>
          <p className="text-secondary-200 mb-4">
            I dati personali sono raccolti tramite:
          </p>
          <ul className="list-disc list-inside text-secondary-200 space-y-2 pl-5">
            <li>
              Moduli di contatto e prenotazione: compilati volontariamente
              dall’utente.
            </li>
            <li>
              Navigazione del sito web: raccolta automatica di dati tecnici
              tramite strumenti di analytics.
            </li>
            <li>
              Iscrizione a newsletter o comunicazioni commerciali (se
              applicabile): previa espressione del consenso.
            </li>
          </ul>
        </section>

        <section className="mb-12 text-left">
          <h3 className="text-2xl md:text-4xl mb-4 font-semibold text-secondary-200">
            14. Minori
          </h3>
          <p className="text-secondary-200 mb-4">
            Il sito web e i servizi offerti non sono destinati a minori di 16
            anni. Qualora si venga a conoscenza che dati personali di minori
            sono stati raccolti senza il consenso dei genitori o tutori legali,
            il Titolare provvederà a cancellarli tempestivamente.
          </p>
        </section>

        <section className="mb-12 text-left">
          <h3 className="text-2xl md:text-4xl mb-4 font-semibold text-secondary-200">
            15. Link ad Altri Siti Web
          </h3>
          <p className="text-secondary-200">
            Il sito potrebbe contenere link a siti web di terzi. Il Titolare non
            è responsabile delle politiche sulla privacy adottate da tali siti e
            invita gli utenti a leggere le relative informative privacy.
          </p>
        </section>

        <section className="mb-12 text-left">
          <h3 className="text-2xl md:text-4xl mb-4 font-semibold text-secondary-200">
            16. Responsabile della Protezione dei Dati (DPO)
          </h3>
          <p className="text-secondary-200 mb-4">
            In base all’art. 37 del Regolamento (UE) 2016/679, il Titolare ha
            designato un Responsabile della Protezione dei Dati (DPO). Tuttavia,
            nel caso specifico, [inserire se applicabile].
          </p>
        </section>

        <section className="mb-12 text-left">
          <h3 className="text-2xl md:text-4xl mb-4 font-semibold text-secondary-200">
            17. Modifiche all’Informativa Privacy
          </h3>
          <p className="text-secondary-200 mb-4">
            Il Titolare si riserva il diritto di apportare modifiche alla
            presente Informativa Privacy. Le eventuali modifiche saranno
            pubblicate sul sito web e, se necessario, comunicate agli
            interessati. Si invita pertanto l’utente a consultare periodicamente
            questa pagina per essere sempre aggiornato sulle eventuali
            modifiche.
          </p>
        </section>

        <section className="mb-12 text-left">
          <h3 className="text-2xl md:text-4xl mb-4 font-semibold text-secondary-200">
            18. Contatti
          </h3>
          <p className="text-secondary-200 mb-4">
            Per ulteriori informazioni o chiarimenti in merito alla presente
            Informativa Privacy, è possibile contattare il Titolare del
            Trattamento ai seguenti recapiti:
          </p>
          <ul className="list-none text-secondary-200 space-y-1">
            <li>
              <strong>Email:</strong>{" "}
              <a
                href="mailto:info@casadipinta.com"
                className="underline text-secondary-400 hover:text-secondary-500"
              >
                info@casadipinta.com
              </a>
            </li>
            <li>
              <strong>PEC:</strong>{" "}
              <a
                href="mailto:bellotto.michele@pec.it"
                className="underline text-secondary-400 hover:text-secondary-500"
              >
                bellotto.michele@pec.it
              </a>
            </li>
            <li>
              <strong>Telefono:</strong> +39 0427 41697
            </li>
            <li>
              <strong>Indirizzo:</strong> VIA PIAVE 1, 33097 SPILIMBERGO (PN)
            </li>
          </ul>
        </section>

        <section className="mb-12 text-left">
          <h3 className="text-2xl md:text-4xl mb-4 font-semibold text-secondary-200">
            Allegati e Riferimenti Normativi
          </h3>
          <ul className="list-disc list-inside text-secondary-200 space-y-2 pl-5">
            <li>
              <a
                href="https://eur-lex.europa.eu/legal-content/IT/TXT/?uri=CELEX%3A32016R0679"
                className="underline text-secondary-400 hover:text-secondary-500"
                target="_blank"
                rel="noopener noreferrer"
              >
                Regolamento (UE) 2016/679 (GDPR)
              </a>
            </li>
            <li>
              <a
                href="[Inserire link alla Cookie Policy]"
                className="underline text-secondary-400 hover:text-secondary-500"
                target="_blank"
                rel="noopener noreferrer"
              >
                Cookie Policy
              </a>
            </li>
          </ul>
        </section>
      </motion.div>
    </main>
  );
}

export default PrivacyPolicy;
